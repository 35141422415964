import { Tooltip as ReactTooltip } from "react-tooltip";

const Tooltip = ({ text }) => {
  const randomNum = Math.floor(Math.random() * 1000);

  return (
    <>
      <a
        data-tooltip-id={`tooltip-${randomNum}`}
        style={{
          background: "#aaa",
          color: "#fff",
          display: "inline-flex",
          width: "24px",
          height: "24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}
      >
        <i className="bx bx-question-mark" />
      </a>
      <ReactTooltip clickable id={`tooltip-${randomNum}`} className="tooltip-content">
        <div style={{ padding: "8px 4px" }}>
          <h6 style={{ color: "#fff" }}>Data Calculation Formulas</h6>
          <p style={{ margin: 0 }}>{text}</p>
        </div>
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
