import { validateState } from "../../common/functions";
import { CHANGE_LOCATION } from "./actionType";

const INIT_STATE = {
  currentLocation: (() => {
    const location = window.localStorage.getItem("location");
    if (location) {
      const locationData = JSON.parse(location);
      const validLocation = validateState(locationData);
      return (
        validLocation || {
          state_id: "TX",
          state_name: "Texas",
        }
      );
    } else {
      return {
        state_id: "TX",
        state_name: "Texas",
      };
    }
  })(),
};

const Location = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCATION:
      return { ...state, currentLocation: action.payload };

    default:
      return state;
  }
};

export default Location;
