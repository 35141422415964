import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6} xs={6}>
                        eIQdigital
                        </Col>
                        <Col sm={6} xs={6} className="text-end">
                            {/* <img src="/img/eiqdigital_logo.png"  height="30" alt="" onClick={()=>{window.open('https://www.eiqdigital.com/')}} style={{cursor:'pointer'}} /> */}
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;