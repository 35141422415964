import React, { useContext, useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { fetchBadPlans, fetchEflDoc } from '../api/myplan'
import Loader from './Common/Loader'
import { Utility } from '../App'
import { NoResult } from './Common/NoResult'
import { errornotify } from './Message'
import { jumpTo } from './UrlModal'
import { IconButton, Tooltip } from '@mui/material'
import moment from 'moment'
// import { errornotify } from './Message'

interface badPlanObj {
    plan_id: string
    utility_id: string
    provider_id: string
    plan_name: string
    contract_term: string
    cancel_fee: string
    efl_url: string
    source: string
    offline_ts: string
    diff: string
    utility_name: string
    pd_id: string
    energy_charge1: string
    new_price500: string
    new_price1000: string
    new_price2000: string
    price500: string
    price1000: string
    price2000: string
    created_ts: string
    diff_type: string
    diff_updated_ts:string
}

export default function UnpublishedPlan() {
    // const { getEflDoc } = useContext(Utility)
    const [isLoading, setIsLoading] = useState(false)
    const [isNoData, setIsNoData] = useState(false)
    const [plans, setPlans] = useState<Array<badPlanObj>>([])
    const badPlans = async () => {
        setIsLoading(true)
        const res = await fetchBadPlans()
        if (res.status === 1) {
            setIsNoData(false)
        } else {
            setIsLoading(false)
            setIsNoData(true)
            return
        }

        setPlans(res.response)
        setIsLoading(false)
    }
    useEffect(() => {
        badPlans()
    }, [])


    const Tooltips = (props: any) => {
        let { text, id } = props;

        let title = `This is calculated rate for ${text} with latest TDU charges.`
        return <Tooltip title={id === 4 ? REASONCHART : title} placement={id === 4 ? 'right' : 'top'}>
            <IconButton>
                <img src='/question-mark-icon.png' alt='' width={15} />
            </IconButton>
        </Tooltip>
    }

    const REASONCHART = <React.Fragment>
        <p>The price shown in the EFL differs from the price on the source website, or there is a notable price difference between the EFL and our algorithm's calculation.</p>
        <p>Mismatched source web rate: The price listed in the EFL matches with our calculated rate, but the price on the source website does not.</p>
        <p>Mismatched EFL rate: The price on the source website matches with our calculated rate, but the EFL price does not.</p>
        <p>Calculation discrepancy: Our calculation indicates a different price compared to the price shown on the source website and in the EFL. Likely due to TDU fee adjustment.</p>
    </React.Fragment>

    const getReason = (val: any) => {
        let str = 'undefined';
        if (val == 1) {
            str = 'Mismatched EFL rate.'
            return str;
        }
        if (val == 2) {
            str = 'Calculation discrepancy.'
            return str;
        }
        if (val == 3) {
            str = 'Mismatched source web rate.'
            return str;
        }
    }

    const BadPlan = () => {

        const columns = [
            {
                name: <span className='font-weight-bold fs-14'>Created Date</span>,
                selector: (row: badPlanObj) => row.created_ts,
            },
            {
                name: <span className='font-weight-bold fs-14'>Source</span>,
                selector: (row: badPlanObj) => row.source,
            },
            {
                name: <span className='font-weight-bold fs-14'>Plan Name</span>,
                selector: (row: badPlanObj) => {
                    return <div><i className="bx bx-error me-2" />{row.plan_name}</div>
                },
            },
            {
                name: <span className='font-weight-bold fs-14'>Term</span>,
                selector: (row: badPlanObj) => `${row.contract_term}`,
                width: '130px',
            },
            {
                name: <div className='badPlanTitleDiv'> <span className='font-weight-bold fs-14'>500 kWh</span><Tooltips text={'500kwh'} id={1} /></div>,
                selector: (row: badPlanObj) => {
                    return <div><span className='new_price'>{(+row.new_price500).toFixed(2)}¢</span></div>
                },
            },
            {
                name: <div className='badPlanTitleDiv'><span className='font-weight-bold fs-14'>1000 kWh</span> <Tooltips text={'1000kwh'} id={2} /></div>,
                selector: (row: badPlanObj) => {
                    return <div><span className='new_price'>{(+row.new_price1000).toFixed(2)}¢</span></div>
                },
            },
            {
                name: <div className='badPlanTitleDiv'><span className='font-weight-bold fs-14'>2000 kWh  </span><Tooltips text={'2000kwh'} id={3} /></div>,
                selector: (row: badPlanObj) => {
                    return <div><span className='new_price'>{(+row.new_price2000).toFixed(2)}¢</span></div>
                },
            },
            {
                name: <div className='badPlanTitleDiv'><span className='font-weight-bold fs-14'>Reason </span><Tooltips text='' id={4} /></div>,
                width: '200px',
                id: 'season',
                selector: (row: badPlanObj) => {
                    return `${getReason(row.diff_type)}`
                }
            }
            ,
            {
                name: <span className='font-weight-bold fs-14'>Inspection date</span>,
                width:'200px',
                selector: (row: badPlanObj) => {
                    return `${row.diff_updated_ts}`
                }
            }
            ,
            {
                name: <span className='font-weight-bold fs-14'></span>,
                selector: (row: badPlanObj): any => {
                    return (
                        <Button onClick={() => { jumpTo(row.plan_id, row.pd_id); }} outline target="_blank" rel="noreferrer" className="btn btn-sm">EFL</Button>
                    );
                }
            }
        ];
        const customStyles = {
            headCells: {
                style: {
                    backgroundColor: '#F2F2F2',
                    maxWidth: '60'
                },
            }
        };



        return (
            <DataTable
                columns={columns}
                data={plans}
                paginationPerPage={10}
                customStyles={customStyles}
                pagination
                className='unpublishedPlan'
            />
        );
    };

    return (
        <div className='layout-width'>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Plan Audit</h4>
                </CardHeader>
                <CardBody>
                    {
                        isLoading ?
                            <Loader /> :
                            (
                                isNoData ?
                                    <NoResult /> :
                                    <BadPlan />
                            )
                    }
                </CardBody>
            </Card>
        </div>
    )
}
