import {
    api
} from '../config'
import { getToken } from './common'

export const fetchMarketPrice = async (utilityId /*, tduRequest*/ ) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', utilityId)
    // formdata.append('tdu_request', tduRequest)
    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Home/market_price`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchLoginMarketPrice = async (utilityId /*, tduRequest*/ ) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', utilityId)
    // formdata.append('tdu_request', tduRequest)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Market/market_price`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchUtilityId = async () => {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
    }
    const url = `${api.API_URL}Home/get_utility`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchProviderList = async () => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Market/get_login_provider`
    const response = await fetch(url, requestOptions)
    return response.json()
}
export const fetchLoginProviderList = async () => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Market/get_provider`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchAvgPrice = async (value) => {
    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    // formdata.append('tdu_request', value.tdu_request)
    // formdata.append('date', value.date)
    if (+value.term) formdata.append('term', +value.term)
    if (+value.type) formdata.append('type',+value.type)
    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Trend/market_average_price`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchLoginAvgPrice = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    // formdata.append('tdu_request', value.tdu_request)
    formdata.append('date', value.date)
    if (+value.term) formdata.append('term', +value.term)
    if (+value.provider_id) formdata.append('provider_id', +value.provider_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Market/market_average_price`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchLowestPrice = async (value) => {
    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    // formdata.append('tdu_request', value.tdu_request)
    // formdata.append('date', value.date)
    if (+value.term) formdata.append('term', +value.term)
    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Trend/market_lowest_price`
    const response = await fetch(url, requestOptions)
    return response.json()
}
export const fetchLoginLowestPrice = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    // formdata.append('tdu_request', value.tdu_request)
    formdata.append('date', value.date)
    if (+value.term) formdata.append('term', +value.term)
    if (+value.provider_id) formdata.append('provider_id', +value.provider_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Market/market_lowest_price`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchTerms = async (utility_id) => {
    const formdata = new FormData()
    formdata.append('utility_id', utility_id)
    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Home/get_all_term`
    const response = await fetch(url, requestOptions)
    return response.json()
}
export const fetchLoginTerms = async (provider_id, utility_id) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', utility_id)
    formdata.append('provider_id', provider_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Home/get_all_term`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchTopPlanList = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    formdata.append('created_ts', value.created_ts)
    if (+value.term) formdata.append('term', +value.term)
    if (+value.provider_id) formdata.append('provider_id', +value.provider_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}TopPlan/index`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchTopPlanDetail = async (plan_id, price, created_ts, date) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('plan_id', plan_id)
    formdata.append('price', price)
    formdata.append('created_ts', created_ts)
    formdata.append('date', date)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}TopPlan/top_plan_detail`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const exportTopPlanList = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    formdata.append('created_ts', value.created_ts)
    if (+value.term) formdata.append('term', +value.term)
    if (+value.provider_id) formdata.append('provider_id', +value.provider_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}TopPlan/export_top_plan`
    const response = await fetch(url, requestOptions)
    return response
}

export const fetchInfo = async () => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        // body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}CompanyInformation/get_information`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const saveInfo = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('email', value.email)
    formdata.append('plan_picker', value.plan_picker)
    formdata.append('plan_report', value.plan_report)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}CompanyInformation/store_information`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchRace = async (utility_id, idArr, price) => {
    const formdata = new FormData()
    formdata.append('utility_id', utility_id)
    for (let index = 0; index < idArr.length; index++) {
        formdata.append(`provider_id[${index}]`, idArr[index])
    }
    formdata.append('price', price)
    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Home/mobility_rank`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchProviders = async () => {

    const requestOptions = {
        method: 'GET',
        // headers: myHeaders,
        // body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}Home/get_provider`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const contactUs = async (value) => {
    const formdata = new FormData()
    formdata.append('email', value.email)
    formdata.append('message', value.question)
    formdata.append('name', `${value.last_name} ${value.first_name}`)
    formdata.append('company', value.company)
    formdata.append('site', 'PowerRateIndex')

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `https://www.powerlego.com/contact/website`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getPlanNum = async () => {
    const requestOptions = {
        method: 'GET',
        // headers: myHeaders,
        // body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}Home/get_plan_provider_number`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchAllProviderList = async () => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }
    const url = `${api.API_URL}PowerRateIndex/providers`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const updateProvider = async (value) => {

    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('provider_id', value)

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        headers: myHeaders,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}PowerRateIndex/editProvider`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchAverageRateList = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('price', value.price)
    
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Home/averagerate`
    const response = await fetch(url, requestOptions)
    return response.json()
}