import { BarDatum, ResponsiveBar } from "@nivo/bar";
import {
  ChartBlock,
  ChartFooter,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipYItem,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "./Styles/Chart.styled";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";

interface MyBarChartProps {
  type: "PeakTimeBarChart" | "AllPTCRateBarChart";
  title?: string;
  data: BarDatum[];
  size?: string;
  loading?: boolean;
  footer?: string;
}

const colors = [
  "#37A2DA",
  "#32C5E9",
  "#67E0E3",
  "#9FE6B8",
  "#FFDB5C",
  "#ff9f7f",
  "#fb7293",
  "#E062AE",
  "#E690D1",
  "#e7bcf3",
  "#9d96f5",
  "#8378EA",
  "#96BFFF",
];

export const MyBarChart: React.FC<MyBarChartProps> = ({
  type,
  title,
  data,
  size,
  loading,
  footer,
}) => {
  const chartContent = () => {
    if (loading) return <Loader />;

    if (data.length > 0) {
      let chartProps = {};

      if (type === "PeakTimeBarChart") {
        chartProps = {
          keys: ["on_peak", "off_peak", "mid_peak", "super_off_peak"],
          indexBy: "date",
          margin: { top: 50, right: 130, bottom: 50, left: 60 },
          padding: 0.3,
          groupMode: "grouped",
          valueScale: { type: "linear" },
          indexScale: { type: "band", round: true },
          colors: (bar: any) => {
            switch (bar.id) {
              case "on_peak":
                return colors[0];
              case "off_peak":
                return colors[2];
              case "mid_peak":
                return colors[4];
              case "super_off_peak":
                return colors[6];
            }
          },
          borderColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          axisTop: null,
          axisRight: null,
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Month",
            legendPosition: "middle",
            legendOffset: 32,
            // truncateTickAt: 0,
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Trends",
            legendPosition: "middle",
            legendOffset: -40,
            // truncateTickAt: 0,
          },
          enableLabel: false,
          labelSkipWidth: 12,
          labelSkipHeight: 12,
          labelTextColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          legends: [
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ],
          role: "application",
          ariaLabel: "Rate Trends by Peak Time",
          barAriaLabel: (e: any) =>
            e.id + ": " + e.formattedValue + " - " + e.indexValue,
        };
      }

      if (type === "AllPTCRateBarChart") {
        chartProps = {
          keys: ["ptc"],
          indexBy: "utility_code",
          margin: { top: 0, right: 40, bottom: 50, left: 60 },
          padding: 0.7,
          valueScale: { type: "linear" },
          indexScale: { type: "band", round: true },
          colors: (bar: any) => colors[bar.index] || colors[0],
          borderColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          axisTop: null,
          axisRight: null,
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Utility",
            legendPosition: "middle",
            legendOffset: 32,
            // truncateTickAt: 0,
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Rate",
            legendPosition: "middle",
            legendOffset: -40,
            // truncateTickAt: 0,
          },
          enableLabel: false,
          labelSkipWidth: 12,
          labelSkipHeight: 12,
          labelTextColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          tooltip: (bar: any) => {
            return (
              <CustomTooltip>
                <CustomTooltipYItem>
                  <CustomTooltipYLabel color={bar.color}>
                    <i />
                    <span>{bar.indexValue}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{`${bar.value}¢`}</CustomTooltipYVal>
                </CustomTooltipYItem>
              </CustomTooltip>
            );
          },
          role: "application",
          ariaLabel: "All PTC Rate",
          barAriaLabel: (e: any) =>
            e.id + ": " + e.formattedValue + " - " + e.indexValue,
        };
      }

      return <ResponsiveBar data={data} {...chartProps} />;
    }
    return <NoResult />;
  };

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper xs={size && size === "xs" ? true : false}>
        {chartContent()}
      </ChartWrapper>
      {footer && <ChartFooter>{footer}</ChartFooter>}
    </ChartBlock>
  );
};
