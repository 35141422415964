import React, { useEffect, useState, useContext } from 'react'
import Loader from './Common/Loader'
import { fetchMarketPrice } from '../api/market'
import '../styles/market.css'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { errornotify } from './Message'
import { Utility } from '../App'
import { UtilityDataTypes } from '../types'
import { IconButton, Tooltip } from '@mui/material'

interface resObjectInfo {
    [key: string]: any
}

let marketDataObj: Array<resObjectInfo> = [
    { data: [] },
    { data: [] },
    { data: [] },
]

const isMobileLayout = document.body.clientWidth <= 786;

const MarketSummary = () => {
    const { utilityList } = useContext(Utility)
    const [isLoading, setIsLoading] = useState(false)
    const [marketData, setMarketData] = useState(marketDataObj)
    const [date, setDate] = useState({ start: '-', end: '-' })
    const [showTooltip, setShowTooltip] = useState([false, false, false, false, false]);


    const getAllMarket = async () => {
        setIsLoading(true)
        const promise = utilityList.map((item: UtilityDataTypes) => {
            return fetchMarketPrice(item.utility_id)
        })
        const res = await Promise.all(promise);
        if (res && res[0]) setDate(res[0].response.time);

        let resData = res.map((item) => {
            if (item.status !== 1) {
                errornotify(item.message || 'Error')
                return {}
            }
            let data: Array<Object> = []
            Object.keys(item.response).forEach((n: any) => {
                data.push(item.response[n])
            })
            return { data: data }
        })

        setMarketData(resData)
        setIsLoading(false)
    }

    useEffect(() => {
        getAllMarket()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utilityList])


    const handleTouchStart = (id: any) => {
        let arr = showTooltip;
        arr.splice(id, 1, !showTooltip[id]);
        setShowTooltip([...arr]);
    }


    const Tooltips = (props: any) => {
        let { id } = props;
        return (isMobileLayout ? <Tooltip title={`Based on ${date?.start.substr(0, 7) || '-'} and ${date?.end.substr(0, 7) || '-'} data`}
            placement={'top'} style={{ padding: 0 }}
            open={showTooltip[id]}>
            <IconButton onTouchStart={() => { handleTouchStart(id) }}>
                <img src='/question-mark-icon.png' alt='' width={17} />
            </IconButton>
        </Tooltip> :
            <Tooltip title={`Based on ${date?.start.substr(0, 7) || '-'} and ${date?.end.substr(0, 7) || '-'} data`}
                placement={'top'} style={{ padding: 0 }}>
                <IconButton>
                    <img src='/question-mark-icon.png' alt='' width={17} />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <div className="layout-width">
            <Card style={{ minHeight: '250px' }}>
                <CardHeader className='basedData-card'>
                    <h4 className="card-title mb-0 flex-grow-1">Market – Average Rate by TDU/Usage</h4>
                    <div className="flex-shrink-0">
                        <span className="fs-16 text-muted">Based on {date?.start.substr(0, 7) || '-'} and {date?.end.substr(0, 7) || '-'} data</span>
                    </div>
                </CardHeader>

                <CardBody>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Row className='home-market-row'>
                            {marketData.map((item, index) => (
                                Object.keys(item).length > 0 &&
                                <Col key={index} span={2}  style={{ minWidth: 350, maxWidth: 380 }}>
                                    <Card>
                                        <CardHeader className="align-items-center">
                                            <h5 className=" mb-0 flex-grow-1" style={{ fontSize: 16 }}>{utilityList[index]?.utility_name || ''}</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="table-responsive table-card">
                                                <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
                                                    <thead className="text-muted bg-soft-light">
                                                        <tr>
                                                            <th>kWh</th>
                                                            <th>Average Price</th>
                                                            <th>Trend Change <Tooltips id={index} /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>500</td>
                                                            <td>{item?.data[0]?.average_price500 || ''}¢</td>
                                                            <td>
                                                                <span className={'mb-0 ' + classNames(+item?.data[0]?.trend_rate500.substr(0, item?.data[0]?.trend_rate500.length - 1) > 0 ? 'text-danger' : 'text-success')}>
                                                                    <i className={"align-middle me-1 " + classNames(+item?.data[0]?.trend_rate500.substr(0, item?.data[0]?.trend_rate500.length - 1) > 0 ? 'mdi mdi-trending-up' : 'mdi mdi-trending-down')}></i>
                                                                    {item?.data[0]?.trend_rate500 || ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>1000</td>
                                                            <td>{item?.data[1]?.average_price1000 || ''}¢</td>
                                                            <td>
                                                                <span className={'mb-0 ' + classNames(+item?.data[1]?.trend_rate1000.substr(0, item?.data[1]?.trend_rate1000.length - 1) > 0 ? 'text-danger' : 'text-success')}>
                                                                    <i className={"align-middle me-1 " + classNames(+item?.data[1]?.trend_rate1000.substr(0, item?.data[1]?.trend_rate1000.length - 1) > 0 ? 'mdi mdi-trending-up' : 'mdi mdi-trending-down')}></i>
                                                                    {item?.data[1]?.trend_rate1000 || ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2000</td>
                                                            <td>{item?.data[2]?.average_price2000 || ''}¢</td>
                                                            <td>
                                                                <span className={'mb-0 ' + classNames(+item?.data[2]?.trend_rate2000.substr(0, item?.data[2]?.trend_rate2000.length - 1) > 0 ? 'text-danger' : 'text-success')}>
                                                                    <i className={"align-middle me-1 " + classNames(+item?.data[2]?.trend_rate2000.substr(0, item?.data[2]?.trend_rate2000.length - 1) > 0 ? 'mdi mdi-trending-up' : 'mdi mdi-trending-down')}></i>
                                                                    {item?.data[2]?.trend_rate2000 || ''}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                </CardBody>
            </Card>
        </div>
    )
}

export default MarketSummary
