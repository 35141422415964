import { createContext, useEffect, useState } from "react";
import "./assets/scss/themes.scss";
import 'react-tooltip/dist/react-tooltip.css';
import Route from "./Routes/index";
import { fetchUtilityId } from "./api/market";
import { fetchEflDoc } from "./api/myplan";
import { errornotify } from "./Components/Message";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { eflUrl, eflUrlDisplay } from "./store/actions";
import { UtilityDataTypes } from "./types";
import { validateState } from "./common/functions";
import { fetchUSNUtilityId } from "./api/usnMarket";
// import { UrlModal } from "./Components/UrlModal";

export const Utility = createContext<any>({});

export default function App() {
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const [utilityList, setUtilityList] = useState<UtilityDataTypes[]>([]);

  const getUtilityId = async (stateId: string) => {
    const isTX = stateId === "TX";
    const res = isTX
      ? await fetchUtilityId()
      : await fetchUSNUtilityId(stateId);
      
    if (res.status === 1) {
      if (isTX) {
        setUtilityList(res.response);
      } else {
        setUtilityList(res.response.electricity);
      }
    } else {
      errornotify(res.message);
    }
  };

  useEffect(() => {
    const location = window.localStorage.getItem("location");
    let validLocation = null;
    if (location) {
      const locationData = JSON.parse(location);
      validLocation = validateState(locationData);
    }
    getUtilityId(validLocation?.state_id || "TX");
  }, [currentLocation]);

  const getEflDoc = async (plan_id: any, pd_id: any) => {
    const res = await fetchEflDoc(plan_id, pd_id);
    if (res.status === 1) {
      dispatch(eflUrl(res.response));
      dispatch(eflUrlDisplay(true));
    } else {
      return errornotify(res.message);
    }
  };

  return (
    <div className="App">
      <Utility.Provider value={{ utilityList, getEflDoc }}>
        <Route />
      </Utility.Provider>
      <ToastContainer />
      {/* <UrlModal/> */}
    </div>
  );
}
