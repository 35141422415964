export const StateList = [
  {
    state_id: "TX",
    state_name: "Texas",
  },
  // {
  //   state_id: "CA",
  //   state_name: "California",
  // },
  {
    state_id: "CT",
    state_name: "Connecticut",
  },
  {
    state_id: "DC",
    state_name: "District of Columbia",
  },
  {
    state_id: "DE",
    state_name: "Delaware",
  },
  {
    state_id: "GA",
    state_name: "Georgia",
  },
  {
    state_id: "IL",
    state_name: "Illinois",
  },
  {
    state_id: "IN",
    state_name: "Indiana",
  },
  {
    state_id: "KY",
    state_name: "Kentucky",
  },
  {
    state_id: "MA",
    state_name: "Massachusetts",
  },
  {
    state_id: "MD",
    state_name: "Maryland",
  },
  {
    state_id: "ME",
    state_name: "Maine",
  },
  {
    state_id: "MI",
    state_name: "Michigan",
  },
  {
    state_id: "NH",
    state_name: "New Hampshire",
  },
  {
    state_id: "NJ",
    state_name: "New Jersey",
  },
  {
    state_id: "NY",
    state_name: "New York",
  },
  {
    state_id: "OH",
    state_name: "Ohio",
  },
  {
    state_id: "PA",
    state_name: "Pennsylvania",
  },
  {
    state_id: "RI",
    state_name: "Rhode Island",
  },
  {
    state_id: "VA",
    state_name: "Virginia",
  },
];
