import React, { useEffect, useState, useContext } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { Utility } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { fetchPeakTimeRateTrends } from "../api/usnMarket";
import { MyBarChart } from "./MyBarChart";
import { BarDatum } from "@nivo/bar";
import TduSelector from "./TduSelector";
import { monthToType } from "../api/common";
import { Auth } from "aws-amplify";
import { changeGetAccessModalDisplay } from "../store/actions";
import { successnotify } from "./Message";

const USN_PeaktimeRateTrends = () => {
  const { utilityList } = useContext(Utility);
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [utilityId, setUtilityId] = useState("");
  const [range, setRange] = useState("3");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<BarDatum[]>([]);

  useEffect(() => {
    if (utilityList?.[0]) {
      setUtilityId(utilityList[0].utility_id);
    }
  }, [currentLocation, utilityList]);

  const getData = async () => {
    setIsLoading(true);

    const requestData = {
      state_id: currentLocation?.state_id,
      utilityId: utilityId,
      type: monthToType(range),
    };

    const res = await fetchPeakTimeRateTrends(...Object.values(requestData));

    if (res?.status === 1) {
      setData(res.response || []);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!utilityId) return;
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, utilityId]);

  const checkAuthAndSetValue = async (val: string) => {
    if (val !== "3") {
      const auth = await Auth.currentUserInfo();
      if (auth) {
        setRange(val);
      } else {
        // if (localStorage.getItem("received_inquiry")) {
        //   successnotify(
        //     "We have received your inquiry. Please be patient, and we will contact you as soon as possible!"
        //   );
        // } else {
          dispatch(changeGetAccessModalDisplay(true));
        // }
      }
    } else {
      setRange(val);
    }
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Rate Trends by Peak Time
          </h4>
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-between flex-wrap gap-2 mb-5">
            <TduSelector
              variant="buttonGroup"
              value={utilityId}
              handleChange={(val: string) => setUtilityId(val)}
            />
            <div className="d-flex flex-wrap gap-2">
              {["3", "6", "12"].map((val: string, idx: number) => (
                <Button
                  onClick={() => checkAuthAndSetValue(val)}
                  color={range === val ? "secondary" : "light"}
                  key={idx}
                >
                  {val} Months
                </Button>
              ))}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <MyBarChart
                type="PeakTimeBarChart"
                title={`${range}-Month Rate Trends`}
                // footer="Month/Year"
                data={data}
              />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_PeaktimeRateTrends;
