import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchKWHPrice } from '../api/myplan'
import Loader from './Common/Loader'
import { errornotify } from './Message'
import { MyChart } from './MyChart'

interface kwhModalProps {
    plan_id: string,
    pd_id: string,
}
interface kwhTypes {
    id:string,
    data:any[]
}

export const KWHPriceChart: React.FC<kwhModalProps> = ({
    plan_id,
    pd_id
}) => {
    const [data, setData] = useState<kwhTypes[]>([])
    const { kwhData } = useSelector((state: any) => ({
        kwhData: state.chat.kwhData
    }))
    const [isLoading, setIsLoading] = useState(false)

    const getPriceData = async () => {
        setIsLoading(true)
        const res = await fetchKWHPrice(plan_id, pd_id)
        if (res.status !== 1) {
            setIsLoading(false)
            return errornotify(res.message || 'Error!')
        }
        let chatData = []
        for (const key in res.response) {
            chatData.push({ x: +key, y: +res.response[key] })
        }
        setData([{ id: 'kWh Price', data: chatData }])
        setIsLoading(false)
    }
    useEffect(() => {
        if (kwhData) getPriceData()
    }, [kwhData])

    return (
        <React.Fragment>
            {
            isLoading ?
                <Loader/> :
                <MyChart
                    title='Plan Price Trend by kWh'
                    type='kwh'
                    data={data[0]?.data.length ? data : []}
                />
            }
        </React.Fragment>
    )
}
