import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Utility } from '../App';
import Loader from './Common/Loader';
import '../styles/customize.css'
import { fetchUsagePlans, fetchUsages } from '../api/customize';
import { errornotify } from './Message';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { UtilityDataTypes } from '../types';
import { useSelector } from 'react-redux';
import { fetchEflDoc } from '../api/myplan';
import {  jumpTo } from './UrlModal';

interface usagePlanObj {
    plan_id: string,
    pd_id: string,
    plan_name: string,
    provider_id: string,
    utility_id: string,
    provider_name: string,
    utility_name: string,
    term: string,
    your_rate: string,
    cancel_fee: string,
    created_ts: string,
    offline_ts: string
}
interface usagesArr {
    big: Array<number>,
    apartment: Array<number>,
    medium: Array<number>,
    small: Array<number>,
}

const today = moment().format('YYYY-MM-DD');

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export default function Customize() {
    const { getEflDoc, utilityList } = useContext(Utility)
    const [isLoading, setIsLoading] = useState(false)
    const [dataPlanList, setDataPlanList] = useState<Array<usagePlanObj>>([])
    const [values, setValues] = useState<any>([])
    const [btnName, setBtnName] = useState('big')
    const [usagesNums, setUsagesNums] = useState<usagesArr>({ big: [], apartment: [], medium: [], small: [] })
    const [value, setValue] = React.useState<any>(today)
    const [utilityId, setUtilityId] = useState('1')
    const [filterValue, setFilterValue] = useState({
        utility_id: 1,
        created_ts: today,
        usages: []
    })

    let chartData = []
    for (let i in values) {
        chartData.push({ name: months[parseInt(i) - 1], value: parseInt(values[i]) })
    }


    const getPlanList = async (val: any) => {
        setIsLoading(true)
        const res = await fetchUsagePlans(val)
        if (res.status !== 1) {
            return setIsLoading(false)
        }
        setDataPlanList(res.response)
        setIsLoading(false)
    }

    const getUsagesNums = async () => {
        const res = await fetchUsages()
        if (res.status !== 1) return errornotify(res.message)
        setUsagesNums(res.response)
        setValues(res.response.big)
        setFilterValue({
            ...filterValue,
            usages: res.response.big
        })
    }
    useEffect(() => {
        getUsagesNums()
    }, [])

    useEffect(() => {
        if (filterValue.usages.length !== 0) getPlanList(filterValue)
    }, [filterValue])

    const changeValue = (evt: any) => {
        const val = evt.target.value;
        const key = +evt.target.name;
        let newValues = Object.assign({}, values);
        newValues[key] = val ? parseInt(val) : 0;
        setValues(newValues);
    }

    const changeUtilityId = (val: any) => {
        setUtilityId(val)
        setFilterValue({
            ...filterValue,
            utility_id: val,
        })
    }
    const changeDate = (val: any) => {
        setFilterValue({
            ...filterValue,
            created_ts: moment(val).format('YYYY-MM-DD'),
        })
    }

    const TopPlan = () => {
        const columns = [
            {
                name: <span className='font-weight-bold fs-14'>REP Name</span>,
                selector: (row: any) => row.provider_name,
            },
            {
                name: <span className='font-weight-bold fs-14'>Plan Name</span>,
                selector: (row: any) => row.plan_name,
            },
            {
                name: <span className='font-weight-bold fs-14'>
                    Term
                </span>,
                selector: (row: any) => `${row.term}`,
                width: '70px'
            },
            {
                name: <span className='font-weight-bold fs-14'>Your Rate</span>,
                selector: (row: any) => `${row.your_rate}¢`,
                width: '130px'
            },
            {
                name: <span className='font-weight-bold fs-14'>Cancellation Fee</span>,
                selector: (row: any) => row.cancel_fee,
            },
            {
                name: <span className='font-weight-bold fs-14'>Created Date</span>,
                selector: (row: any) => row.created_ts,
            },
            {
                name: <span className='font-weight-bold fs-14'></span>,
                selector: (row: any) => {
                    return (
                        <div className="d-flex flex-wrap gap-2">
                            <Button onClick={() => { jumpTo(row.plan_id, row.pd_id)}} outline target="_blank" rel="noreferrer" className="btn btn-sm">EFL</Button>
                        </div>
                    );
                },
            },
        ];
        const customStyles = {
            headCells: {
                style: {
                    backgroundColor: '#F2F2F2',
                },
            }
        };

        return (
            <DataTable
                columns={columns}
                data={dataPlanList}
                paginationPerPage={10}
                customStyles={customStyles}
                pagination
            />
        );
    };
    return (
        <div className='page-content'>
            <div className='layout-width d-flex justifyContent-between gap-4 customize-box'>
                <div className="col-4">
                    <Card className='customize-l'>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Create Usage Profile</h4>
                        </CardHeader>
                        <CardBody>
                            <ResponsiveContainer width="100%" height={130}>
                                <BarChart
                                    data={chartData}
                                    margin={{
                                        top: 12, right: 24, left: -12, bottom: 0,
                                    }}
                                >
                                    <XAxis dataKey="name" tick={{ fontSize: 11 }} stroke="#999" interval={1} />
                                    <YAxis tick={{ fontSize: 11, fill: '#999' }} stroke="#fff" />
                                    <CartesianGrid vertical={false} strokeDasharray="2" />
                                    <Tooltip
                                        itemStyle={{
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            color: '#fff'
                                        }}
                                        labelStyle={{
                                            fontSize: 11,
                                            fontWeight: 'normal',
                                            color: '#fff'
                                        }}
                                        contentStyle={{
                                            backgroundColor: 'rgba(0, 0, 0, .6)',
                                            border: 0,
                                            borderRadius: 4
                                        }}
                                        cursor={{
                                            fill: '#f7f7f7'
                                        }}
                                        content={({ active, payload, label }) => {
                                            if (active && label && payload) {
                                                return (
                                                    <div className="chart-tooltip">
                                                        <div className="date">{label}</div>
                                                        <div className="value">{payload[0].value + 'kwh'}</div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        }}
                                    />
                                    <Bar dataKey="value"
                                        isAnimationActive={true}
                                        radius={10}
                                        maxBarSize={10}
                                    >
                                        {chartData.map((entry, idx) => (
                                            <Cell key={idx} fill="#D1D9D7" />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                            <div className="d-flex flex-wrap gap-2 mt-3 mb-3">
                                <Button
                                    onClick={() => {
                                        setValues(usagesNums?.big)
                                        setBtnName('big')
                                    }}
                                    color={btnName === 'big' ? 'secondary' : 'light'}
                                >Big House</Button>
                                <Button
                                    onClick={() => {
                                        setValues(usagesNums?.apartment)
                                        setBtnName('apartment')
                                    }}
                                    color={btnName === 'apartment' ? 'secondary' : 'light'}
                                >Apartment</Button>
                                <Button
                                    onClick={() => {
                                        setValues(usagesNums?.medium)
                                        setBtnName('medium')
                                    }}
                                    color={btnName === 'medium' ? 'secondary' : 'light'}
                                >Medium</Button>
                                <Button
                                    onClick={() => {
                                        setValues(usagesNums?.small)
                                        setBtnName('small')
                                    }}
                                    color={btnName === 'small' ? 'secondary' : 'light'}
                                >Small House</Button>
                            </div>
                            {values.length === 0 ? (
                                <Loader />
                            ) : (
                                <form>
                                    <Row>
                                        <Col span={4}><Label>Jan:</Label><Input name='1' value={values[0]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Feb:</Label><Input name='2' value={values[1]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Mar:</Label><Input name='3' value={values[2]} onChange={(evt) => changeValue(evt)} /></Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col span={4}><Label>Apr:</Label><Input name='4' value={values[3]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>May:</Label><Input name='5' value={values[4]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Jun:</Label><Input name='6' value={values[5]} onChange={(evt) => changeValue(evt)} /></Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col span={4}><Label>Jul:</Label><Input name='7' value={values[6]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Aug:</Label><Input name='8' value={values[7]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Sep:</Label><Input name='9' value={values[8]} onChange={(evt) => changeValue(evt)} /></Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col span={4}><Label>Oct:</Label><Input name='10' value={values[9]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Nov:</Label><Input name='11' value={values[10]} onChange={(evt) => changeValue(evt)} /></Col>
                                        <Col span={4}><Label>Dec:</Label><Input name='12' value={values[11]} onChange={(evt) => changeValue(evt)} /></Col>
                                    </Row>
                                    <div className='d-grid mt-4'>
                                        <Button color='secondary' type='button' onClick={(e) => {
                                            e.preventDefault()
                                            setFilterValue({
                                                ...filterValue,
                                                usages: values
                                            })
                                        }}>Confirm</Button>
                                    </div>
                                </form>
                            )}
                        </CardBody>
                    </Card>
                </div>
                <div className="col-8">
                    <Card>
                        <CardBody>
                            <div className="d-flex flex-wrap gap-2 mb-3">
                                {utilityList.map((item: UtilityDataTypes, index: number) => (
                                    <Button
                                        key={index}
                                        onClick={() => changeUtilityId(item.utility_id)}
                                        color={utilityId === item.utility_id ? 'secondary' : 'light'}
                                    >
                                        {item.utility_name}
                                    </Button>
                                ))}
                            </div>
                            <div className="mb-4 d-flex justify-content-between">
                                <div></div>
                                <Flatpickr
                                    className="rank_datepicker"
                                    value={value}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        defaultDate: [today],
                                        minDate: moment().startOf('year').format('YYYY-MM-DD'),
                                        maxDate: today,
                                        allowInput: true,
                                    }}
                                    onChange={([newValue]: any) => {
                                        setValue(moment(newValue).format('YYYY-MM-DD'))
                                        changeDate(newValue)
                                    }}
                                />
                            </div>
                            {isLoading ? (<Loader />) : (<TopPlan />)}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
