import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  const currentLocation = useSelector(
    (state) => state.Location.currentLocation
  );
  const [isHome, setIsHome] = useState(false);
  const [isState, setIsState] = useState(false);
  const [isMarket, setIsMarket] = useState(false);
  const [isPlan, setIsPlan] = useState(false);
  const [isCustomize, setIsCustomize] = useState(false);
  const [isMismatched, setIsMismatched] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Market");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Home") {
      setIsHome(false);
    }
    if (iscurrentState !== "State") {
      setIsState(false);
    }
    if (iscurrentState !== "Market") {
      setIsMarket(false);
    }
    if (iscurrentState !== "Plan") {
      setIsPlan(false);
    }
    if (iscurrentState !== "Customize") {
      setIsCustomize(false);
    }
    if (iscurrentState !== "Mismatched") {
      setIsMismatched(false);
    }
  }, [history, iscurrentState, isMarket, isPlan, isState]);

  const menuItems =
    currentLocation?.state_id === "TX"
      ? [
          {
            label: "Menu",
            isHeader: true,
          },
          {
            id: "home",
            label: "Home",
            icon: "bx bx-home",
            link: "/",
            stateVariables: isHome,
            click: function (e) {
              e.preventDefault();
              setIsHome(!isHome);
              setIscurrentState("Home");
              updateIconSidebar(e);
            },
          },
          {
            id: "market",
            label: "Market View",
            icon: "bx bx-bar-chart-alt-2",
            link: "/market",
            stateVariables: isMarket,
            click: function (e) {
              e.preventDefault();
              setIsMarket(!isMarket);
              setIscurrentState("Market");
              updateIconSidebar(e);
            },
          },
          {
            id: "plan",
            label: "MyPlans",
            icon: "bx bx-copy-alt",
            link: "/plan",
            stateVariables: isPlan,
            click: function (e) {
              e.preventDefault();
              setIsMarket(!isPlan);
              setIscurrentState("Plan");
              updateIconSidebar(e);
            },
          },
          {
            id: "mismatched",
            label: "Plan Audit",
            icon: "ri-table-line",
            link: "/mismatched",
            stateVariables: isMismatched,
            click: function (e) {
              e.preventDefault();
              setIsMarket(!isCustomize);
              setIsPlan(!isCustomize);
              setIscurrentState("Mismatched");
              updateIconSidebar(e);
            },
          },
          {
            id: "customize",
            label: "Profile Match",
            icon: "ri-layout-masonry-line",
            link: "/customize",
            stateVariables: isPlan,
            click: function (e) {
              e.preventDefault();
              setIsMarket(!isCustomize);
              setIsPlan(!isCustomize);
              setIsMismatched(!isCustomize);
              setIscurrentState("Customize");
              updateIconSidebar(e);
            },
          },
        ]
      : [
          {
            label: "Menu",
            isHeader: true,
          },
          {
            id: "home",
            label: "Home",
            icon: "bx bx-home",
            link: "/",
            stateVariables: isHome,
            click: function (e) {
              e.preventDefault();
              setIsHome(!isHome);
              setIscurrentState("Home");
              updateIconSidebar(e);
            },
          },
          {
            id: "state",
            label: "State Insight",
            icon: "bx bx-hive",
            link: "/state-insight",
            stateVariables: isState,
            click: function (e) {
              e.preventDefault();
              setIsState(!isState);
              setIscurrentState("State");
              updateIconSidebar(e);
            },
          },
          {
            id: "market",
            label: "Market View",
            icon: "bx bx-bar-chart-alt-2",
            link: "/market",
            stateVariables: isMarket,
            click: function (e) {
              e.preventDefault();
              setIsMarket(!isMarket);
              setIscurrentState("Market");
              updateIconSidebar(e);
            },
          },
          {
            id: "plan",
            label: "MyPlans",
            icon: "bx bx-copy-alt",
            link: "/plan",
            stateVariables: isPlan,
            click: function (e) {
              e.preventDefault();
              setIsMarket(!isPlan);
              setIscurrentState("Plan");
              updateIconSidebar(e);
            },
          },
        ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
