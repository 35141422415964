import { Helmet } from "react-helmet";
import USN_PTCPriceUtilityHistory from "../Components/USN_PTCPriceUtilityHistory";
import USN_AllPTCRate from "../Components/USN_AllPTCRate";
import USN_AvgLowestPTCRate from "../Components/USN_AvgLowestPTCRate";

export const StateInsight = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <USN_AllPTCRate />
      <USN_AvgLowestPTCRate />
      <USN_PTCPriceUtilityHistory />
    </div>
  );
};
