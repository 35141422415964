import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { changeLocation } from "../../store/actions";
import { StateList } from "./StateList";

const LocationDropdown = () => {
  const dispatch = useDispatch();
  const currentLocation = useSelector(
    (state) => state.Location.currentLocation
  );

  const [isLocationDropdown, setIsLocationDropdown] = useState(false);
  const toggleLocationDropdown = () => {
    setIsLocationDropdown(!isLocationDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLocationDropdown}
        toggle={toggleLocationDropdown}
        className="ms-sm-3 header-item"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <i className="ri-map-pin-line" />
            <span className="text-start ms-xl-2">
              {currentLocation?.state_name}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {StateList.map((item) => (
            <DropdownItem
              key={item.state_id}
              onClick={() => {
                if (currentLocation?.state_id === item.state_id) return;

                if (item.state_id === "TX") {
                  window.location.href = "/";
                }

                dispatch(changeLocation(item));
                window.localStorage.setItem("location", JSON.stringify(item));
              }}
              className="d-flex align-items-center justify-content-between"
            >
              <span>{item.state_name}</span>
              {item.state_id === currentLocation?.state_id && (
                <i className="ri-check-line me-1" />
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LocationDropdown;
