import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/myplan.css";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { MyChart } from "./MyChart";
import { DataSeriesTypes } from "../types";
import { errornotify } from "./Message";
import { NoResult } from "./Common/NoResult";
import { jumpTo } from "./UrlModal";
import { useSelector } from "react-redux";
import {
  exportUSNPlanList,
  fetchUSNPlanDetail,
  fetchUSNPlanList,
} from "../api/usnPlan";
import { getLogoSrc } from "../helpers/func";
import TduSelector from "./TduSelector";
import Datepicker from "./Datepicker";

interface planObj {
  cancel_fee: string;
  created_ts: string;
  diff: string;
  efl_url: string;
  offline_ts: string;
  pd_id: string;
  plan_id: string;
  plan_name: string;
  price500: string;
  price1000: string;
  price2000: string;
  provider_id: string;
  provider_name: string;
  source: string;
  term: string;
  utility_id: string;
  utility_name: string;
  contract_term: string;
  rate: string;
}

interface detail {
  plan: any;
}

const today = moment().format("YYYY-MM-DD");

const calDate = (val: string) => {
  return moment(val, "YYYY-MM-DD").subtract(30, "days").format("YYYY-MM-DD");
};

const USN_MyPlansList = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTDU, setselectedTDU] = useState<any>();
  const [filterValue, setFilterValue] = useState({
    utility_id: "",
    created_ts: today,
  });
  const [dataPlanList, setDataPlanList] = useState<Array<planObj>>([]);
  const [termValue, setTermValue] = useState("All");
  const [findData, setFindData] = useState(true);
  const [openSelect, setOpenSelect] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  useEffect(() => {
    setselectedTDU("All TDU");
    setFilterValue({
      ...filterValue,
      utility_id: "",
    });
  }, [currentLocation]);

  const getPlanList = async (val: any) => {
    setIsLoading(true);
    setDataPlanList([]);
    setFindData(false);
    const res = await fetchUSNPlanList(
      currentLocation?.state_id,
      val.created_ts,
      val.utility_id
    );
    if (res.status !== 1 || res.response.length === 0) {
      setFindData(false);
      return setIsLoading(false);
    }
    setFindData(true);
    setDataPlanList(res.response);
    setIsLoading(false);
  };

  useEffect(() => {
    getPlanList(filterValue);
  }, [filterValue]);

  const changeUtilityId = (item: any) => {
    let val = item.utility_id;
    if (item) {
      setselectedTDU(item.utility_name);
      setFilterValue({
        ...filterValue,
        utility_id: val,
      });
    } else {
      setselectedTDU("All TDU");
      setFilterValue({
        ...filterValue,
        utility_id: "",
      });
    }
  };

  const changeDate = (val: any) => {
    setFilterValue({
      ...filterValue,
      created_ts: moment(val).format("YYYY-MM-DD"),
    });
  };

  let filterData = +termValue
    ? dataPlanList.filter((item) => +item.contract_term === +termValue)
    : dataPlanList;
  let terms: any[] = [];
  dataPlanList.map((item) => {
    return terms.push(item.contract_term);
  });
  terms.sort((a, b) => a - b);
  terms.unshift("All");
  let filterTerms = [...new Set(terms)];

  const TopPlan = () => {
    const columns = [
      {
        name: <span className="font-weight-bold fs-14">REP</span>,
        selector: (row: planObj) => {
          return (
            <img
              src={getLogoSrc(row.provider_id, currentLocation?.state_id)}
              alt=""
              height={40}
            />
          );
        },
      },
      {
        name: <span className="font-weight-bold fs-14">Plan Name</span>,
        selector: (row: planObj) => row.plan_name,
      },
      {
        name: (
          <span className="font-weight-bold fs-14">
            <Dropdown
              isOpen={openSelect}
              toggle={() => setOpenSelect(!openSelect)}
            >
              <DropdownToggle tag="button" className="btn ps-0 pe-0">
                <b>
                  {Number.isInteger(parseInt(termValue))
                    ? `${termValue} Month`
                    : "Term"}
                </b>{" "}
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu
                style={{ overflowY: "scroll", maxHeight: "15rem", zIndex: "1" }}
              >
                {filterTerms.map((item: any) => (
                  <DropdownItem key={item} onClick={() => setTermValue(item)}>
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </span>
        ),
        selector: (row: planObj) => `${row.contract_term}`,
        style: {
          paddingLeft: "1.5rem",
        },
        width: "95px",
      },
      {
        name: <span className="font-weight-bold fs-14">Rate</span>,
        selector: (row: planObj) => `${row.rate}¢`,
      },
      {
        name: <span className="font-weight-bold fs-14">Cancellation Fee</span>,
        selector: (row: planObj) => row.cancel_fee,
      },
      {
        name: <span className="font-weight-bold fs-14">Source</span>,
        selector: (row: planObj) => row.source,
      },
      {
        name: <span className="font-weight-bold fs-14"></span>,
        selector: (row: planObj): any => {
          return (
            <div className="d-flex flex-wrap gap-2">
              <Button
                color="secondary"
                outline
                className="btn btn-sm"
                onClick={() => handleOpen(row.plan_id, row.pd_id)}
              >
                Detail
              </Button>
              <Button
                onClick={() => {
                  jumpTo(row.plan_id, row.pd_id);
                }}
                outline
                className="btn btn-sm"
              >
                EFL
              </Button>
            </div>
          );
        },
      },
    ];
    const customStyles = {
      headCells: {
        style: {
          backgroundColor: "#F2F2F2",
          paddingRight: 0,
        },
      },
    };

    return (
      <DataTable
        columns={columns}
        data={filterData}
        paginationPerPage={20}
        customStyles={customStyles}
        pagination
      />
    );
  };

  const [open, setOpen] = React.useState(false);
  const [isShow, setIsShow] = useState(false);
  const [planDetail, setPlanDetail] = useState<detail>({
    plan: {},
  });
  const [detailData, setDetailData] = useState<DataSeriesTypes[]>([]);
  const [findDetailData, setFindDetailData] = useState(true);

  const handleOpen = async (plan_id: any, pd_id: any) => {
    setOpen(true);
    setIsShow(true);
    const res = await fetchUSNPlanDetail(
      plan_id,
      pd_id,
      `${calDate(filterValue.created_ts)}&${filterValue.created_ts}`
    );

    const resData: any = () => {
      if (res.status === 1) {
        return res.response;
      } else {
        setFindDetailData(false);
        setIsShow(false);
        return errornotify(res.message);
      }
    };

    setFindDetailData(true);

    const series: any[] = [];
    resData()?.rank_trend.forEach((item: any) => {
      series.push({ x: item.date, y: item.rank || null, detail: item });
    });

    setDetailData([{ id: resData()?.plan?.provider_name, data: series }]);

    setPlanDetail({
      plan: resData()?.plan || {},
    });
    setIsShow(false);
  };

  const handleClose = () => setOpen(false);

  const filterSelections = () => {
    let list = [],
      key = 0;
    if (termValue !== "All") {
      list.push(
        <button
          key={key++}
          className="d-flex align-items-center btn-soft-secondary btn-sm me-2"
          onClick={() => setTermValue("All")}
        >
          {`Term: ${termValue}`}
          <i className="bx bx-x fs-15 ms-2" />
        </button>
      );
    }
    return list.length > 0 ? (
      <div className="d-flex align-items-center">
        Active Filters:
        <div className="d-flex ms-3">{list}</div>
      </div>
    ) : null;
  };

  const exportList = async () => {
    setIsDownload(true);
    const res = await exportUSNPlanList(
      currentLocation?.state_id,
      filterValue.created_ts,
      filterValue.utility_id,
      "1"
    );

    const fileName = res.headers
      .get("content-disposition")
      ?.split("filename=")[1];

    const data = await res.blob();

    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      fileName || `MyPlans_${filterValue.created_ts}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownload(false);
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "30rem" }}>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            MyPlans – Ranking by Rate
          </h4>
          <div className="flex-shrink-0">
            {!isDownload ? (
              <Button
                color="secondary"
                onClick={exportList}
                className="btn-label"
              >
                <i className="bx bx-download label-icon align-middle fs-17 me-2"></i>
                <span>Download</span>
              </Button>
            ) : (
              <Button color="secondary" className="btn-load" outline>
                <span className="d-flex align-items-center">
                  <Spinner size="sm" className="flex-shrink-0">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                  <span className="flex-grow-1 ms-2">Loading...</span>
                </span>
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-between flex-wrap gap-2 mb-3">
            <div>
              <TduSelector
                value={selectedTDU}
                handleChange={(val: string) => changeUtilityId(val)}
              />
            </div>
            <Datepicker handleChangeDate={(val: any) => changeDate(val)} />
          </div>
          <div className="mb-4 d-flex justify-content-between">
            <div>{filterSelections()}</div>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <div className="rdt_out">
              {findData ? <TopPlan /> : <NoResult />}
            </div>
          )}
        </CardBody>
      </Card>

      <Modal size="lg" isOpen={open} toggle={handleClose} centered>
        <ModalHeader toggle={handleClose}>
          My Plan Detail
          <span className="fs-14 text-muted ms-3">
            {calDate(filterValue.created_ts) || "-"} -{" "}
            {filterValue.created_ts || "-"}
          </span>
        </ModalHeader>
        {findDetailData ? (
          <div className="modal-body">
            {isShow ? (
              <Loader />
            ) : (
              <React.Fragment>
                <Table className="align-middle table-fixed mb-0">
                  <thead>
                    <tr>
                      <th scope="col">REP Name</th>
                      <th scope="col">TDU Name</th>
                      <th scope="col">Term</th>
                      <th scope="col">Base Rate</th>
                      <th scope="col">Plan Source</th>
                      <th scope="col">Cancellation Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{planDetail.plan.provider_name}</td>
                      <td>{planDetail.plan.utility_name}</td>
                      <td>{planDetail.plan.contract_term}</td>
                      <td>
                        {planDetail.plan.rate}
                        {planDetail.plan.rate ? "¢" : ""}
                      </td>
                      <td>{planDetail.plan.source || ""}</td>
                      <td>{planDetail.plan.cancel_fee}</td>
                    </tr>
                  </tbody>
                </Table>
                <MyChart
                  type="myplan"
                  title="Rank Trend"
                  data={detailData}
                  size="xs"
                />
              </React.Fragment>
            )}
          </div>
        ) : (
          <NoResult />
        )}
      </Modal>
    </div>
  );
};

export default USN_MyPlansList;
