import React, { useContext, useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { MyChart } from "./MyChart";
import { errornotify, successnotify } from "./Message";
import { useDispatch, useSelector } from "react-redux";
import { fetchUSNPTCRetailHeadroom } from "../api/usnMarket";
import TduSelector from "./TduSelector";
import moment from "moment";
import { Utility } from "../App";
import { monthToType } from "../api/common";
import { Auth } from "aws-amplify";
import { changeGetAccessModalDisplay } from "../store/actions";
import Tooltip from "./Common/Tooltip";

const USN_PTCRetailHeadroom = () => {
  const { utilityList } = useContext(Utility);
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [utilityId, setUtilityId] = useState("");
  const [range, setRange] = useState("3");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [dataSource, setDataSource] = useState([]);

  const transformChartData = (rawData: any) => {
    const transformedData: any = [
      {
        id: "first_standard_rate",
        data: [],
      },
      {
        id: "over_standard_rate",
        data: [],
      },
    ];
    const otherChargesMap: any = {};
    const allDates = [...new Set(rawData.map((item: any) => item.date))];
    const allOtherChargeTitles = [
      ...new Set(
        rawData.flatMap((item: any) =>
          item.other_charge_ptc.map((charge: any) => charge.title)
        )
      ),
    ];
    allDates.forEach((date: any) => {
      const item = rawData.find((d: any) => d.date === date) || {};
      const formattedDate = moment(date, "YYYY-MM").format("MM/DD/YYYY");

      transformedData[0].data.push({
        x: formattedDate,
        y: item.first_standard_rate || 0,
      });
      transformedData[1].data.push({
        x: formattedDate,
        y: item.over_standard_rate || 0,
      });

      allOtherChargeTitles.forEach((title: any) => {
        if (!otherChargesMap[title]) {
          otherChargesMap[title] = allDates.map((d: any) => ({
            x: moment(d, "YYYY-MM").format("MM/DD/YYYY"),
            y: 0,
          }));
        }
      });

      (item.other_charge_ptc || []).forEach((charge: any) => {
        const index = allDates.indexOf(date);
        otherChargesMap[charge.title][index].y = charge.value || 0;
      });
    });

    for (const [title, data] of Object.entries(otherChargesMap)) {
      transformedData.push({
        id: title,
        data: data,
      });
    }
    return transformedData;
  };

  const getAvgPrice = async () => {
    setIsLoading(true);

    const requestData = {
      state_id: currentLocation?.state_id,
      utilityId: utilityId,
      type: monthToType(range),
    };

    const res = await fetchUSNPTCRetailHeadroom(...Object.values(requestData));

    if (res?.status === 1) {
      const { response } = res || {};

      const result = transformChartData(response);

      setData(result);

      const { data_source } = res || {};
      if (data_source) {
        setDataSource(Object.values(data_source));
      }
    } else {
      setData([]);
      // return errornotify(res.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (utilityList?.[0]) {
      setUtilityId(utilityList[0].utility_id);
    }
  }, [currentLocation, utilityList]);

  useEffect(() => {
    if (!utilityId) return;
    getAvgPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, utilityId]);

  const checkAuthAndSetValue = async (val: string) => {
    if (val !== "3") {
      const auth = await Auth.currentUserInfo();
      if (auth) {
        setRange(val);
      } else {
        // if (localStorage.getItem("received_inquiry")) {
        //   successnotify(
        //     "We have received your inquiry. Please be patient, and we will contact you as soon as possible!"
        //   );
        // } else {
        dispatch(changeGetAccessModalDisplay(true));
        // }
      }
    } else {
      setRange(val);
    }
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Retail Headroom by PTC
          </h4>
          {dataSource && (
            <Tooltip
              text={
                <div>
                  {dataSource.map((item, idx) => (
                    <p key={idx}>
                      <a href={item}>{item}</a>
                    </p>
                  ))}
                </div>
              }
            />
          )}
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-wrap justify-content-between gap-2 mb-5">
            <TduSelector
              variant="buttonGroup"
              value={utilityId}
              handleChange={(val: string) => setUtilityId(val)}
            />
            <div className="d-flex flex-wrap gap-2">
              {["3", "6", "12"].map((val: string, idx: number) => (
                <Button
                  onClick={() => checkAuthAndSetValue(val)}
                  color={range === val ? "secondary" : "light"}
                  key={idx}
                >
                  {val} Months
                </Button>
              ))}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <MyChart
                type="homeprice"
                title={`${range}-Month Rate Trends`}
                footer="Month/Year"
                data={data[0]?.data.length ? data : []}
              />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_PTCRetailHeadroom;
