import { StateList } from "../Components/Common/StateList";
import { DataSeriesTypes, DataPointTypes } from "../types";

export const getMaxChartValue = (preppedData: DataSeriesTypes[]) => {
    const maxArray = preppedData.map(data => {
        if (data.data.length) {
            const dataset = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            const showDatas = data.data.filter((item, index) => !isNaN(Number(item?.y)));
                console.log()
            return (dataset as DataPointTypes[]).reduce((max: number, p: DataPointTypes) => (p?.y > max ? p?.y : max), showDatas[0]?.y)
        } else {
            return 0
        }
    });

    const max = Math.max(...maxArray);

    return Math.ceil(max) + 2;
};

export const getMinChartValue = (preppedData: DataSeriesTypes[]) => {
    const maxArray = preppedData.map(data => {
        if (data.data.length) {
            const dataset = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            const showDatas = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            return (dataset as DataPointTypes[]).reduce((min: number, p: DataPointTypes) => (p?.y < min ? p?.y : min), showDatas[0]?.y)
        } else {
            return 10000
        }
    });

    const min = Math.min(...maxArray);

    return Math.floor(min) - 2;
};

export const getPRIMaxChartValue = (preppedData: DataSeriesTypes[]) => {
    const maxArray = preppedData.map(data => {
        if (data.data.length) {
            const dataset = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            const showDatas = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            return (dataset as DataPointTypes[]).reduce((max: number, p: DataPointTypes) => (p?.y > max ? p?.y : max), showDatas[0]?.y)
        } else {
            return 0
        }
    });

    const max = Math.max(...maxArray);
    if (max < 1) return 1;
    if (max > 1 && max < 2) return 2;
    if (max > 2) return 3;
};

export const getPRIMinChartValue = (preppedData: DataSeriesTypes[]) => {
    const maxArray = preppedData.map(data => {
        if (data.data.length) {
            const dataset = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            const showDatas = data.data.filter((item, index) => !isNaN(Number(item?.y)));
            return (dataset as DataPointTypes[]).reduce((min: number, p: DataPointTypes) => (p?.y < min ? p?.y : min), showDatas[0]?.y)
        } else {
            return 10000
        }
    });

    const min = Math.min(...maxArray);
    if (min > -1) return -1;
    if (min < -1 && min >- 2) return -2;
    if (min < -2) return -3;
};

export const validateState = (location: {state_id: string; state_name: string;}) => {
    return StateList.find(
        (state) => state.state_id === location?.state_id
    );
}