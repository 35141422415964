import { api } from "../config";
import { getToken } from "./common";

export const fetchUSNPTCRateTrend = async (
  state_id,
  service_type,
  date
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("date", date);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/StateDetail/ptc_history_list`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const exportUSNPTCRateHistory = async (
  state_id,
  service_type,
  date
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("date", date);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/StateDetail/export_ptc_history_list`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNAllPTCRate = async (state_id, service_type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/StateDetail/state_ptc`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchAvgLowestPTCRateUSN = async (
  state_id,
  service_type,
  date
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("date", date);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/StateDetail/average_and_lowest_ptc`;
  const response = await fetch(url, requestOptions);
  return response.json();
};
