import React, { useState } from 'react'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import { Auth } from 'aws-amplify';
import { changePasswordDisplay } from "../store/actions";
import { useDispatch, useSelector } from 'react-redux';
import { errornotify } from './Message';


export const ChangePassword = () => {
    const dispatch = useDispatch()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    // const [showPwd, setShowPwd] = useState(false)
    const { openChangePassword } = useSelector((state: any) => ({
        openChangePassword: state.Layout.openChangePassword
    }))
    const handleClose = () => {
        dispatch(changePasswordDisplay(false))
        setOldPassword('')
        setNewPassword('')
    }

    const handleChange = (oldPassword: any, newPassword: any, e:any) => {
        e.preventDefault()
        setIsLoading(true)
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                setIsLoading(false)
                handleClose()
            })
            .catch(err => {
                setIsLoading(false)
                return errornotify(err.message)
            });
    }
    return (
        <Modal isOpen={openChangePassword} toggle={handleClose} centered>
            <ModalHeader toggle={handleClose}>Change Password</ModalHeader>
            <form>
            <ModalBody>
                <div className="mb-3">
                    <Label>Old Password *</Label>
                    <Input name='oldPassword' type='password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                </div>
                <div className="mb-3">
                    <Label>New Password *</Label>
                    <div className="form-icon right">
                        <Input name='newPassword' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        {/* {showPwd?(
                            <i className='ri-eye-line' style={{cursor:'pointer'}} onClick={()=>{setShowPwd(false)
                            }}/>
                        ):(
                            <i className='ri-eye-off-line' style={{cursor:'pointer'}} onClick={(e)=>{setShowPwd(true)}}/>
                        )} */}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                {isLoading ? (
                    <Button color="secondary" className="btn-load" outline>
                        <span className="d-flex align-items-center">
                            <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                            <span className="flex-grow-1 ms-2">
                                Loading...
                            </span>
                        </span>
                    </Button>) : (
                    <Button color='secondary' disabled={(oldPassword===''||newPassword==='')} onClick={(e) => handleChange(oldPassword, newPassword, e)}>Submit</Button>
                )}
            </ModalFooter>
            </form>
        </Modal>
    )
}