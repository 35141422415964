import React, { useEffect, useState } from 'react'
import Loader from './Common/Loader'
import '../styles/myplan.css'
import moment from 'moment'
import { fetchEflDoc, fetchMyPlanDetail, fetchPlanList } from '../api/myplan'
import { Button, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, Table } from 'reactstrap'
import Flatpickr from "react-flatpickr";
import DataTable from 'react-data-table-component'
import { MyChart } from './MyChart'
import { DataSeriesTypes } from '../types'
import { errornotify } from './Message'
import { NoResult } from './Common/NoResult'
import { jumpTo } from './UrlModal'
import TduSelector from './TduSelector'

interface planObj {
    cancel_fee: string
    created_ts: string
    diff: string
    efl_url: string
    offline_ts: string
    pd_id: string
    plan_id: string
    plan_name: string
    price500: string
    price1000: string
    price2000: string
    provider_id: string
    provider_name: string
    source: string
    term: string
    utility_id: string
    utility_name: string
}

interface detail {
    plan: any
}

const today = moment().format('YYYY-MM-DD');

const calDate = (val: string) => {
    return moment(val, 'YYYY-MM-DD').subtract(30, 'days').format('YYYY-MM-DD');
}

const MyPlanList = () => {
    const [utilityId, setUtilityId] = useState('1')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedTDU,setselectedTDU] = useState<any>();

    const [filterValue, setFilterValue] = useState({
        utility_id: '',
        term: '',
        created_ts: today,
    })
    const [value, setValue] = React.useState<any>(today)
    const [dataPlanList, setDataPlanList] = useState<Array<planObj>>([])
    const [termValue, setTermValue] = useState('All')
    const [findData, setFindData] = useState(true)
    const [openSelect, setOpenSelect] = useState(false)
    const [selectedNum, setSelectedNum] = useState('500');

    const getPlanList = async (val: any) => {
        setIsLoading(true)
        // setTermValue('All')
        setDataPlanList([])
        setFindData(false)
        const res = await fetchPlanList(val)
        if (res.status !== 1 || res.response.length === 0) {
            setFindData(false)
            return setIsLoading(false)
        }
        setFindData(true)
        setDataPlanList(res.response)
        setIsLoading(false)
    }
    useEffect(() => {
        getPlanList(filterValue)
    }, [filterValue])

    const changeUtilityId = (item: any) => {
        let val = item.utility_id;
        if (item) {
            setselectedTDU(item.utility_name);
            setUtilityId(val)
            setFilterValue({
                ...filterValue,
                utility_id: val,
            })
        }else{
            setselectedTDU('All TDU');
            setUtilityId('')
            setFilterValue({
                ...filterValue,
                utility_id: '',
            })
        }
       
     
    }
    const changeDate = (val: any) => {
        setFilterValue({
            ...filterValue,
            created_ts: moment(val).format('YYYY-MM-DD'),
        })
    }

    let filterData = +termValue ? dataPlanList.filter(item => +item.term === +termValue) : dataPlanList
    let terms: any[] = []
    dataPlanList.map((item, index) => {
        return terms.push(item.term)
    })
    terms.sort((a, b) => a - b)
    terms.unshift('All')
    let filterTerms = [...new Set(terms)]

    const TopPlan = () => {
        const columns = [
            {
                name: <span className='font-weight-bold fs-14'>REP</span>,
                selector: (row: planObj) => { return <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${row.provider_id}`} alt="" height={40} /> }
            },
            {
                name: <span className='font-weight-bold fs-14'>Plan Name</span>,
                selector: (row: planObj) => row.plan_name,
            },
            {
                name: <span className='font-weight-bold fs-14'>
                    <Dropdown isOpen={openSelect} toggle={() => setOpenSelect(!openSelect)}>
                        <DropdownToggle tag="button" className="btn ps-0 pe-0">
                            <b>{Number.isInteger(parseInt(termValue)) ? `${termValue} Month` : 'Term'}</b> <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu style={{ overflowY: 'scroll', maxHeight: '15rem', zIndex: '1' }}>
                            {filterTerms.map((item: any) => (
                                <DropdownItem key={item} onClick={() => setTermValue(item)}>
                                    {item}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </span>,
                selector: (row: planObj) => `${row.term}`,
                style: {
                    paddingLeft: '1.5rem'
                },
                width: '95px'
            },
            {
                name: <span className='font-weight-bold fs-14'>{`${selectedNum} kWh`}</span>,
                selector: (row: planObj) => `${selectedNum === '500' ? row.price500 : selectedNum === '1000' ? row.price1000 : row.price2000}¢`,
                width: '110px'
            },
            {
                name: <span className='font-weight-bold fs-14'>Cancellation Fee</span>,
                selector: (row: planObj) => row.cancel_fee,
            },
            {
                name: <span className='font-weight-bold fs-14'>Source</span>,
                selector: (row: planObj) => row.source,
            },
            {
                name: <span className='font-weight-bold fs-14'></span>,
                selector: (row: planObj): any => {
                    return (
                        <div className="d-flex flex-wrap gap-2">
                            <Button color="secondary" outline className="btn btn-sm"
                                onClick={() =>
                                    handleOpen(
                                        row.plan_id,
                                        row.price500,
                                        row.created_ts
                                    )}
                            >Detail</Button>
                            <Button onClick={() => { jumpTo(row.plan_id, row.pd_id) }} outline className="btn btn-sm">EFL</Button>
                        </div>
                    );
                },
            },
        ];
        const customStyles = {
            headCells: {
                style: {
                    backgroundColor: '#F2F2F2',
                    paddingRight: 0
                },
            }
        };

        return (
            <DataTable
                columns={columns}
                data={filterData}
                paginationPerPage={20}
                customStyles={customStyles}
                pagination
            />
        );
    };

    const [open, setOpen] = React.useState(false)
    const [isShow, setIsShow] = useState(false)
    const [planDetail, setPlanDetail] = useState<detail>({
        plan: {}
    })
    const [detailData, setdetailData] = useState<DataSeriesTypes[]>([])
    const [findDetailData, setFindDetailData] = useState(true)

    const handleOpen = async (plan_id: any, price: any, created_ts: any) => {
        setOpen(true)
        setIsShow(true)
        const res = await fetchMyPlanDetail(plan_id, created_ts, `${calDate(value)}&${value}`)

        const resData: any = () => {
            if (res.status === 1) {
                return res.response
            } else {
                setFindDetailData(false)
                setIsShow(false)
                return errornotify(res.message)
            }
        }

        setFindDetailData(true)

        const p500: any[] = []
        resData().rank_500.map((item: any) => {
            if (item.rank !== 0) return p500.push({ x: item.date, y: item.rank, detail: item })
            else return p500.push({ x: item.date, y: null, detail: item })
        })
        const p1000: any[] = []
        resData().rank_1000.map((item: any) => {
            if (item.rank !== 0) return p1000.push({ x: item.date, y: item.rank, detail: item })
            else return p1000.push({ x: item.date, y: null, detail: item })
        })
        const p2000: any[] = []
        resData().rank_2000.map((item: any) => {
            if (item.rank !== 0) return p2000.push({ x: item.date, y: item.rank, detail: item })
            else return p2000.push({ x: item.date, y: null, detail: item })
        })
        setdetailData([{ id: '2000kWh', data: p2000.filter((i: any) => i.y) }, { id: '1000kWh', data: p1000.filter((i: any) => i.y) }, { id: '500kWh', data: p500.filter((i: any) => i.y) }])
        setPlanDetail({
            plan: resData()?.plan || {}
        })
        setIsShow(false)
    }
    const handleClose = () => setOpen(false)
    const changePrice = (val: any) => {
        setIsLoading(true)
        setSelectedNum(val);
        setTimeout(() => {
            setIsLoading(false);
        }, 1500)
    }

    const filterSelections = () => {
        let list = [], key = 0;
        if (termValue !== 'All') {
            list.push(
                <button key={key++} className="d-flex align-items-center btn-soft-secondary btn-sm me-2" onClick={() => setTermValue('All')}>
                    {`Term: ${termValue}`}
                    <i className="bx bx-x fs-15 ms-2" />
                </button>
            )
        }
        return list.length > 0 ?
            <div className="d-flex align-items-center">
                Active Filters:
                <div className="d-flex ms-3">
                    {list}
                </div>
            </div> :
            null;
    }

    return (
        <div className="layout-width">
            <Card style={{ minHeight: '30rem' }}>
                <CardHeader className="align-items-center justify-content-between d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">MyPlans – Ranking by Rate</h4>
                </CardHeader>
                <CardBody>
                    <div className="d-flex flex-wrap gap-2 mb-3">
                        {/* {utilityList.map((item: any, index: number) => (
                            <Button
                                key={index}
                                onClick={() => changeUtilityId(item.utility_id)}
                                color={utilityId === item.utility_id ? 'secondary' : 'light'}
                            >
                                {item.utility_name}
                            </Button>
                        ))} */}
                        <TduSelector
                            value={selectedTDU}
                            handleChange={(val: string) => changeUtilityId(val)}
                        />
                    </div>

                    <div className="d-flex flex-wrap gap-2">
                        {
                            ['500', '1000', '2000'].map((val, idx) => (
                                <Button
                                    key={idx}
                                    onClick={() => changePrice(val)}
                                    color={selectedNum === val ? 'secondary' : 'light'}
                                >
                                    {val} kWh
                                </Button>
                            ))
                        }
                    </div>
                    <div className="mb-4 d-flex justify-content-between">
                        <div>
                            {filterSelections()}
                        </div>
                        <Flatpickr
                            className="rank_datepicker"
                            value={value}
                            options={{
                                dateFormat: "Y-m-d",
                                defaultDate: [today],
                                minDate: moment().startOf('year').format('YYYY-MM-DD'),
                                maxDate: today,
                                allowInput: true,
                            }}
                            onChange={([newValue]: any) => {
                                setValue(moment(newValue).format('YYYY-MM-DD'))
                                changeDate(newValue)
                            }}
                        />
                    </div>

                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className='rdt_out'>
                            {findData ? <TopPlan /> : <NoResult />}
                        </div>
                    )}
                </CardBody>
            </Card>

            <Modal
                size="lg"
                isOpen={open}
                toggle={handleClose}
                centered
            >
                <ModalHeader toggle={handleClose}>
                    My Plan Detail
                    <span className="fs-14 text-muted ms-3">{calDate(value) || '-'} - {value || '-'}</span>
                </ModalHeader>
                {findDetailData ? (
                    <div className='modal-body'>
                        {isShow ? (
                            <Loader />
                        ) : (
                            <React.Fragment>
                                <Table className="align-middle table-fixed mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">REP Name</th>
                                            <th scope="col">TDU Name</th>
                                            <th scope="col">Term</th>
                                            <th scope="col">Base Rate</th>
                                            <th scope="col">Plan Source</th>
                                            <th scope="col">Cancellation Fee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {planDetail.plan.provider_name}
                                            </td>
                                            <td>
                                                {planDetail.plan.utility_name}
                                            </td>
                                            <td>
                                                {planDetail.plan.contract_term}
                                            </td>
                                            <td>
                                                {planDetail.plan.base_rate}
                                                {planDetail.plan.base_rate ? '¢' : ''}
                                            </td>
                                            <td>
                                                {planDetail.plan.source || ''}
                                            </td>
                                            <td>
                                                {planDetail.plan.cancel_fee}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <MyChart
                                    type="myplan"
                                    title="Rank Trend"
                                    data={detailData}
                                    size="xs"
                                />
                            </React.Fragment>
                        )}
                    </div>
                ) : <NoResult />}
            </Modal >
        </div>
    )
}
export default MyPlanList
