import React, { useContext, useEffect, useState } from 'react'
import Select from "react-select"
import '../styles/market.css'
import { fetchLoginAvgPrice, fetchLoginLowestPrice, fetchTerms } from '../api/market'
import { Context } from '../pages/LoginMarket'
import { Button, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap'
import { MyChart } from './MyChart'
import { DataWithColorSeriesTypes, DataPointTypes, UtilityDataTypes } from '../types'
import { errornotify } from './Message'
import moment from 'moment';
import { Utility } from '../App'
import TduSelector from './TduSelector'

interface searchOptionObj {
    value: any
    label: any
}

const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');

const toChartsData = (inputData: any, outputData: any) => {
    if (inputData) {
        return inputData.map((item: any) => {
            if (item.date && +item.average_price !== 0) {
                return outputData.push({ x: item.date, y: +item.average_price })
            }
        })
    }
    else {
        return outputData = []
    }
}
const toChartsDataLowest = (inputData: any, outputData: any) => {
    if (inputData) {
        return inputData.map((item: any) => {
            if (item.date && +item.lowest_price !== 0) {
                return outputData.push({ x: item.date, y: +item.lowest_price })
            }
        })
    }
    else {
        return outputData = []
    }
}

const LoginMarketTrend = () => {
    const { utilityList } = useContext(Utility)
    const { provider, allProvider } = useContext(Context)
    const [utilityId, setUtilityId] = useState('1')
    const [price, setPrice] = useState('500')
    const [term, setTerm] = useState('')
    const [termList, setTermList] = useState([])
    const [compareProvider, setCompareProvider] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    // const [compareName, setCompareName] = useState(allProvider[0]?.provider_name)

    const [filterValue, setFilterValue] = useState({
        utility_id: '',
        price: 500,
        date: `${startDate}&${endDate}`,
        term: '',
    })
    const [data, setData] = useState<DataWithColorSeriesTypes[]>([])
    const [lowestData, setLowestData] = useState<DataWithColorSeriesTypes[]>([])

    const [providerList, setProviderList] = useState([
        '',
        provider.provider_id,
    ])

    const [openTerms, setOpenTerms] = useState(false)

    const [searchProviderList, setSearchProviderList] = useState<Array<searchOptionObj>>([])
    const [selectedTDU,setselectedTDU] = useState<any>();



    const getAvgPrice = async () => {
        setIsLoading(true)

        const promise = providerList.map((item) => {
            if (+item) {
                return fetchLoginAvgPrice({
                    ...filterValue,
                    provider_id: +item,
                })
            } else {
                return fetchLoginAvgPrice({
                    ...filterValue,
                })
            }
        })
        const res = await Promise.all(promise)
        const resData: any = res.map((item, index) => {
            if (item.status === 1) {
                return item.response.average_price
            } else {
                errornotify(item.message || `Error trend[${index + 1}]`)
                return null
            }
        })

        let avgData: DataPointTypes[] = []
        let providerData: DataPointTypes[] = []
        toChartsData(resData[0], avgData)
        toChartsData(resData[1], providerData)

        setData([
            {
                id: `${provider ? provider.provider_name : ''}`,
                data: providerData,
                color: 'hsl(207,54.0%,46.9%)'
            },
            {
                id: 'Market Price',
                data: avgData,
                color: 'hsl(118,40.6%,48.8%)'
            },
        ])
        setIsLoading(false)
    }
    const getLowestPrice = async () => {
        setIsLoading(true)

        const promise = providerList.map((item) => {
            if (+item) {
                return fetchLoginLowestPrice({
                    ...filterValue,
                    provider_id: +item,
                })
            } else {
                return fetchLoginLowestPrice({
                    ...filterValue,
                })
            }
        })
        const res = await Promise.all(promise)
        const resData: any = res.map((item, index) => {
            if (item.status === 1) {
                return item.response.lowest_price
            } else {
                errornotify(item.message || `Error trend[${index + 1}]`)
                return null
            }
        })

        let avgData: any = []
        let providerData: any = []
        toChartsDataLowest(resData[0], avgData)
        toChartsDataLowest(resData[1], providerData)
        setLowestData([
            {
                id: `${provider ? provider.provider_name : ''}`,
                data: providerData,
                color: 'hsl(207,54.0%,46.9%)'
            },
            {
                id: 'Market Price',
                data: avgData,
                color: 'hsl(118,40.6%,48.8%)'
            },
        ])
        setIsLoading(false)
    }

    useEffect(() => {
        if (+provider.provider_id && allProvider.length) {
            getAvgPrice()
            getLowestPrice()
        } else {
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, utilityList, providerList])


    useEffect(() => {
        const getTerms = async () => {
            const res = await fetchTerms(+utilityId)
            if (res.status !== 1) {
                return
            }
            setTermList(res.response)
        }
        getTerms()
    }, [utilityId])

    useEffect(() => {
        if (+provider.provider_id && allProvider.length) {
            let searchList = allProvider.map((item: any) => {
                return { value: item.provider_id, label: item.provider_name }
            })
            setProviderList(['', provider.provider_id])
            // setCompareName(allProvider[0]?.provider_name)
            setSearchProviderList(searchList)
        } else {
            return
        }
    }, [provider, allProvider])

    useEffect(() => {
        if (compareProvider) {
            changeCompare(compareProvider)
        } else {
            setData((datas) => {
                let arr = datas.slice(0)
                arr.splice(0, 1)
                return arr
            })
            setLowestData((datas) => {
                let arr = datas.slice(0)
                arr.splice(0, 1)
                return arr
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareProvider, filterValue])

    const changeUtilityId = (val: any) => {
        if (!val) {
            setselectedTDU('All TDU')
            setUtilityId('')
            setFilterValue({
                ...filterValue,
                utility_id: '',
            })
        }else{
            setselectedTDU(val.utility_name)
            setUtilityId(val.utility_id)
            setFilterValue({
                ...filterValue,
                utility_id: val.utility_id,
            })
        }
       
       
    }
    const changeUsage = (val: string) => {
        setPrice(val)
        setFilterValue({
            ...filterValue,
            price: +val,
        })
    }

    const changeTerm = (val: any) => {
        setTerm(val)
        setFilterValue({
            ...filterValue,
            term: val,
        })
    }

    const compareFun = (datas: any, val: any, id: any) => {
        let arr = datas.slice(0)
        if (arr.length === 2) {
            arr.unshift({
                id: `${id.label ? id.label : ''}`,
                data: val,
                color: 'hsl(359,79.5%,49.8%)'
            })
        } else {
            arr = datas.map((item: any, index: any) => {
                if (index === 0) {
                    return {
                        id: `${id.label ? id.label : ''}`,
                        data: val,
                        color: 'hsl(359,79.5%,49.8%)'
                    }
                } else {
                    return item
                }
            })
        }
        return arr
    }

    const changeCompare = async (id: any) => {
        setIsLoading(true)
        const avg_res = await fetchLoginAvgPrice({
            ...filterValue,
            provider_id: id.value
        })
        const low_res = await fetchLoginLowestPrice({
            ...filterValue,
            provider_id: id.value
        })
        if (avg_res.status === 1) {
            let avgCompare: any = []
            toChartsData(avg_res.response.average_price, avgCompare)
            setData((datas) => compareFun(datas, avgCompare, id))
        } else {
            errornotify(avg_res.message || `Error trend[average]`)
        }
        if (low_res.status === 1) {
            let lowCompare: any = []
            toChartsDataLowest(low_res.response.lowest_price, lowCompare)
            setLowestData((datas) => compareFun(datas, lowCompare, id))

        } else {
            errornotify(low_res.message || `Error trend[lowest]`)
        }
        setIsLoading(false)
    }

    const compareWith = (obj: any) => {
        setCompareProvider(obj)
        // if (obj) {
        // setCompareName(obj.label)
        // }
    }

    return (
        <div className="layout-width">
            <Card style={{ minHeight: '600px' }}>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Market – Rate Trending/TDU/Usage/Term/Competitor</h4>
                </CardHeader>
                <CardBody>
                    <React.Fragment>
                        <div className="d-flex flex-wrap gap-2">
                            {/* {utilityList.map((item: UtilityDataTypes, index: number) => (
                                <Button
                                    key={index}
                                    onClick={() => changeUtilityId(item.utility_id)}
                                    color={utilityId === item.utility_id ? 'secondary' : 'light'}
                                >
                                    {item.utility_name}
                                </Button>
                            ))} */}
                            <TduSelector
                                value={selectedTDU}
                                handleChange={(val: string) => changeUtilityId(val)}
                            />
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className="d-flex flex-wrap gap-2 mt-3 mb-3">
                                {
                                    ['500', '1000', '2000'].map((val: string, idx: number) => (
                                        <Button
                                            onClick={() => changeUsage(val)}
                                            color={price === val ? 'secondary' : 'light'}
                                            key={idx}
                                        >
                                            {val} kWh
                                        </Button>
                                    ))
                                }
                            </div>
                            <div style={{ width: '20%' }} className="pc-compareSelect">
                                <Label htmlFor="aria-example-input" className="form-label text-muted" style={{ fontWeight: '400' }}>Compare with</Label>
                                <Select
                                    defaultValue={searchProviderList[0]}
                                    value={compareProvider}
                                    isClearable
                                    onChange={(e) => compareWith(e)}
                                    options={searchProviderList}
                                />
                            </div>
                        </div>
                        <Dropdown isOpen={openTerms} toggle={() => setOpenTerms(!openTerms)}>
                            <DropdownToggle tag="button" className="btn btn-light">
                                {term ? `${term} Month` : 'All Term'} <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu style={{ overflow: 'scroll', height: '20rem' }}>
                                <DropdownItem key={'allterm'} onClick={() => changeTerm('')}>
                                    All Term
                                </DropdownItem>
                                {termList.map((item: any, index: any) => (
                                    <DropdownItem key={index} onClick={() => changeTerm(item)}>
                                        {item} Month
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <div style={{ width: '80%' ,marginTop:'10px' }} className="mobile-compareSelect">
                                <Label htmlFor="aria-example-input" className="form-label text-muted" style={{ fontWeight: '400' }}>Compare with</Label>
                                <Select
                                    defaultValue={searchProviderList[0]}
                                    value={compareProvider}
                                    isClearable
                                    onChange={(e) => compareWith(e)}
                                    options={searchProviderList}
                                />
                            </div>
                        <MyChart
                            type='price'
                            title='Average Rate'
                            data={data}
                            loading={isLoading}
                        />
                        <MyChart
                            type='price'
                            title='Lowest Rate'
                            data={lowestData}
                            loading={isLoading}
                        />
                    </React.Fragment>
                </CardBody>
            </Card>
        </div>
    )
}
export default LoginMarketTrend
