import { ResponsiveLine } from "@nivo/line";
import { ChartBlock, ChartFooter, ChartTitle, ChartWrapper, CustomTooltip, CustomTooltipX, CustomTooltipY, CustomTooltipYItem, CustomTooltipYLabel, CustomTooltipYVal } from "./Styles/Chart.styled";
import { BaseChartProps } from '../common/chart-config';
import { DataSeriesTypes } from "../types";
import { getMaxChartValue, getMinChartValue, getPRIMaxChartValue, getPRIMinChartValue } from "../common/functions";
import Loader from './Common/Loader';
import { NoResult } from "./Common/NoResult";
import { multiply } from "../common/calculation";
import moment from "moment";
// import { linearGradientDef } from "@nivo/core";
// import { useSelector } from "react-redux";

interface MyChartProps {
    type: 'price' | 'rank' | 'myplan' | 'homeprice' | 'rankdetail' | 'kwh' | 'powerRateIndex' | 'ptcRateTrend';
    title?: string;
    data: DataSeriesTypes[]
    size?: string;
    loading?: boolean;
    footer?: string;
}

export const MyChart: React.FC<MyChartProps> = ({
    type, title, data, size, loading, footer
}) => {
    // const {myPlanData} = useSelector((state:any)=>({
    //     myPlanData: state.Chats.myPlanData
    // }))
    // const getTooltipData = (price:any,date:any) => {
    //     for (const key in myPlanData) {
    //         if (key.substring(key.length-3,key.length)===price.substring(price.length-3,price.length)) {
    //             return myPlanData[key].find((item:any)=>item.date===date)
    //         }
    //     }
    // }
    const findDetail = (index: any, date: any) => {
        const detail = data[index].data.find((item) => item.x === date)
        return detail
    }
    let isMobileLayout = document.body.clientWidth <= 786;

    const chartContent = () => {
        if (loading) return <Loader />;
        if (data && data.length > 0) {
            const chartProps = JSON.parse(JSON.stringify(BaseChartProps));
            let markert;
            const keys = []
            for (const key in BaseChartProps) {
                keys.push(key)
            }

            if (type === 'price') {
                chartProps.yFormat = (val: number) => val + '¢';
                chartProps.colors = { datum: 'color' };
                chartProps.enableArea = false
                chartProps.axisBottom.tickValues = isMobileLayout ? 'every 5 days' : 'every 2 days';
                chartProps.axisLeft.legend = 'Rate/kWh (in cents)';
                // chartProps.defs= [
                //     linearGradientDef('gradientA', [
                //         { offset: 0, color: 'hsl(118,40.6%,48.8%)', opacity: .4 },
                //         { offset: 10, color: 'hsl(118,40.6%,48.8%)', opacity: 0 }
                //     ]),
                //     linearGradientDef('gradientB', [
                //         { offset: 0, color: 'hsl(207,54.0%,46.9%)', opacity: .4 },
                //         { offset: 10, color: 'hsl(207,54.0%,46.9%)', opacity: 0 }
                //     ]),
                //     linearGradientDef('gradientC', [
                //         { offset: 0, color: 'hsl(359,79.5%,49.8%)', opacity: .4 },
                //         { offset: 10, color: 'hsl(359,79.5%,49.8%)', opacity: 0 }
                //     ])
                // ];
                // chartProps.fill = [{ match: {id:'Market Price'}, id: 'gradientA' },{ match: {color: 'hsl(207,54.0%,46.9%)'}, id: 'gradientB' },{ match: {color: 'hsl(359,79.5%,49.8%)'}, id: 'gradientC' }];
            }
            if (type === 'homeprice') {
                chartProps.yFormat = (val: number) => val + '¢';
                chartProps.axisBottom.tickValues = isMobileLayout ? 'every 6 months' : 'every 2 months';
                chartProps.axisBottom.format = '%b %Y';
                chartProps.axisLeft.legend = 'Rate/kWh (in cents)';
                chartProps.margin.right = 110;
                chartProps.margin.bottom = 50;
                chartProps.legends = [{
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 115,
                    translateY: 0,
                    itemsSpacing: 4,
                    itemDirection: 'left-to-right',
                    symbolShape: 'circle',
                    itemWidth: 100,
                    itemHeight: 20,
                    symbolSize: 14,
                }];

            }
            if (type === 'ptcRateTrend') {
                chartProps.yFormat = (val: number) => val + '¢';
                chartProps.axisBottom.tickValues = isMobileLayout ? 'every 6 months' : 'every 2 months';
                chartProps.axisBottom.format = '%b %Y';
                chartProps.axisLeft.legend = 'Rate/kWh (in cents)';
                chartProps.margin.right = 110;
                chartProps.margin.bottom = 50;
                chartProps.legends = [{
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 115,
                    translateY: 0,
                    itemsSpacing: 4,
                    itemDirection: 'left-to-right',
                    symbolShape: 'circle',
                    itemWidth: 100,
                    itemHeight: 20,
                    symbolSize: 14,
                }];
            }
            if (type === 'powerRateIndex') {
                chartProps.axisLeft.legend = '% Chg vs YA';
                chartProps.axisLeft.tickValues=2;
                chartProps.yFormat = (val: number) => (val * 100).toFixed(0) + '%';
                chartProps.axisBottom.tickValues = isMobileLayout ? 'every 6 months' : 'every 2 months';
                chartProps.axisBottom.format = '%b %Y';
                chartProps.xFormat = 'time:%m/%d/%Y';
                chartProps.legends = [];
                chartProps.margin.bottom = 50;
                chartProps.axisLeft.format = (val:number) => (val * 100).toFixed(0) + '%';

                markert = [{
                    axis: 'y',
                    value: '0',
                    lineStyle: { stroke: '#BBB', strokeWidth: 1 },
                }]
                chartProps.sliceTooltip = ({ slice }: { slice: any }) => {
                    if (slice) {
                        const { points } = slice || {};
                        if (points) {
                            let xVal = '', yVal = '',isRise = true;
                            if (points[0] && points[0].data.xFormatted) {
                                xVal = points[0].data.xFormatted;
                            }
                            if (points[0] && points[0].data.yFormatted) {
                                yVal = points[0].data.yFormatted;
                            }
                            if (yVal.slice(0,1)[0] === '-' ) {
                                isRise = false;
                            } 
                            return (
                                <CustomTooltip>
                                    <CustomTooltipY>
                                        <CustomTooltipYItem>
                                            <CustomTooltipYLabel color={'#00FF00'}>
                                                {moment(xVal).format('MM/YYYY')}
                                            </CustomTooltipYLabel>
                                            <CustomTooltipYVal color={isRise ? '#00E09E' : '#C93756'}> {isRise ? <i className={" align-middle ri-arrow-right-up-line"}></i> :<i className={"align-middle ri-arrow-right-down-line"}></i>}<span>{yVal}</span></CustomTooltipYVal>
                                        </CustomTooltipYItem>
                                    </CustomTooltipY>
                                </CustomTooltip>
                            )
                        }
                    }
                    return null
                }
            }

            if (type === 'rank') {
                chartProps.axisLeft.legend = 'Rank';
                chartProps.axisLeft.format = (val: number) => Math.floor(val);
            }
            if (type === 'myplan') {
                chartProps.axisLeft.legend = 'Rank';
                chartProps.sliceTooltip = ({ slice }: { slice: any }) => {
                    if (slice) {
                        const { points } = slice || {};
                        if (points) {
                            let xVal = '';
                            if (points[0] && points[0].data.xFormatted) {
                                xVal = points[0].data.xFormatted;
                            }
                            return (
                                <CustomTooltip>
                                    <CustomTooltipX>{xVal}</CustomTooltipX>
                                    {
                                        points.map((val: any, idx: number) =>
                                        (<CustomTooltipY key={idx}>
                                            <CustomTooltipYItem>
                                                <CustomTooltipYLabel color={val.serieColor}>
                                                    <i />
                                                    <span>{val.serieId}</span>
                                                </CustomTooltipYLabel>
                                                <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                                            </CustomTooltipYItem>
                                            {findDetail(idx, xVal)?.detail?.rank_rep &&
                                                findDetail(idx, xVal)?.detail?.base_rate &&
                                                <CustomTooltipYItem>
                                                    <CustomTooltipYLabel color={'#fff'}>
                                                        <i />
                                                        <span>REP/Rate</span>
                                                    </CustomTooltipYLabel>
                                                    <CustomTooltipYVal>{findDetail(idx, xVal)?.detail?.rank_rep ? findDetail(idx, xVal)?.detail?.rank_rep : ''}/{findDetail(idx, xVal)?.detail?.base_rate ? findDetail(idx, xVal)?.detail?.base_rate + '¢' : ''}</CustomTooltipYVal>
                                                </CustomTooltipYItem>}
                                            {findDetail(idx, xVal)?.detail?.Rank1_rep &&
                                                findDetail(idx, xVal)?.detail?.Rank1_price &&
                                                <CustomTooltipYItem>
                                                    <CustomTooltipYLabel color={'#fff'}>
                                                        <i />
                                                        <span>REP/Rate (Rank1)</span>
                                                    </CustomTooltipYLabel>
                                                    <CustomTooltipYVal>{findDetail(idx, xVal)?.detail?.Rank1_rep ? findDetail(idx, xVal)?.detail?.Rank1_rep : ''}/{findDetail(idx, xVal)?.detail?.Rank1_price ? findDetail(idx, xVal)?.detail?.Rank1_price + '¢' : ''}</CustomTooltipYVal>
                                                </CustomTooltipYItem>}
                                        </CustomTooltipY>
                                        )
                                        )
                                    }
                                </CustomTooltip>
                            )
                        }
                    }
                    return null
                }
            }
            if (type === 'rankdetail') {
                chartProps.axisLeft.legend = 'Rank';
                chartProps.axisBottom.tickValues = 'every 5 days';
                chartProps.sliceTooltip = ({ slice }: { slice: any }) => {
                    if (slice) {
                        const { points } = slice || {};
                        if (points) {
                            let xVal = '';
                            if (points[0] && points[0].data.xFormatted) {
                                xVal = points[0].data.xFormatted;
                            }
                            return (
                                <CustomTooltip>
                                    <CustomTooltipX>{xVal}</CustomTooltipX>
                                    {
                                        points.map((val: any, idx: number) =>
                                        (<CustomTooltipY key={idx}>
                                            <CustomTooltipYItem>
                                                <CustomTooltipYLabel color={val.serieColor}>
                                                    <i />
                                                    <span>{val.serieId}</span>
                                                </CustomTooltipYLabel>
                                                <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                                            </CustomTooltipYItem>
                                            <CustomTooltipYItem>
                                                <CustomTooltipYLabel color={'#fff'}>
                                                    <i />
                                                    <span>REP/Rate</span>
                                                </CustomTooltipYLabel>
                                                <CustomTooltipYVal>{findDetail(idx, xVal)?.detail?.base_rate ? findDetail(idx, xVal)?.detail?.base_rate + '¢' : ''}</CustomTooltipYVal>
                                            </CustomTooltipYItem>
                                        </CustomTooltipY>
                                        )
                                        )
                                    }
                                </CustomTooltip>
                            )
                        }
                    }
                    return null
                }
            }
            if (type === 'kwh') {
                chartProps.margin = { top: 12, right: 40, bottom: 80, left: 60 }
                chartProps.yFormat = (val: number) => val + '¢';
                chartProps.axisLeft.format = (val: number) => Math.floor(val) + '¢';
                chartProps.axisLeft.legend = ''
                chartProps.legends = []
                chartProps.axisBottom.format = (val: any) => val + 'kWh';
                // chartProps.axisBottom = {tickSize: 4, tickPadding: 16,tickRotation:40};
                chartProps.axisBottom.tickValues = 7
                chartProps.xScale = { type: 'linear' };
                chartProps.xFormat = '>-';
                chartProps.sliceTooltip = ({ slice }: { slice: any }) => {
                    if (slice) {
                        const { points } = slice || {};
                        if (points) {
                            // let xVal = '';
                            // if (points[0] && points[0].data.xFormatted) {
                            //     xVal = points[0].data.xFormatted;
                            // }
                            return (
                                <CustomTooltip>
                                    {
                                        points.map((val: any, idx: number) => (
                                            <div key={idx}>
                                                {/* <CustomTooltipX>{val.data.xFormatted}kWh</CustomTooltipX> */}
                                                <CustomTooltipY className="d-flex">
                                                    <CustomTooltipYLabel color={val.serieColor}>
                                                        <i />
                                                        <span>{val.data.xFormatted}kWh</span>
                                                    </CustomTooltipYLabel>
                                                    <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                                                </CustomTooltipY>
                                            </div>
                                        ))
                                    }
                                </CustomTooltip>
                            )
                        }
                    }
                    return null
                }
            }

            const maxVal = type === 'powerRateIndex' ? getPRIMaxChartValue(data) : getMaxChartValue(data);
            const minVal = type === 'powerRateIndex' ? getPRIMinChartValue(data) : getMinChartValue(data);

            const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }: any) => {

                return series.map(({ id, data, color }: any) => (
                    <path
                        key={id}
                        d={lineGenerator(
                            data.map((d: any) => ({
                                x: xScale(d.data.x),
                                y: yScale(d.data.y)
                            }))
                        )}
                        fill="none"
                        stroke={color}
                        style={
                            id === 'Market Price'
                                ? {
                                    // simulate line will dash stroke when index is even
                                    strokeDasharray: "3, 6",
                                    strokeWidth: 3
                                }
                                : {
                                    // simulate line with solid stroke
                                    strokeWidth: 2.5
                                }
                        }
                    />
                ));
            }

            return (
                <ResponsiveLine
                    {...chartProps}
                    data={data}
                    yScale={{
                        type: 'linear',
                        min: minVal,
                        max: maxVal,
                    }}
                    markers={markert}
                    layers={[
                        // includes all default layers
                        "grid",
                        "markers",
                        "axes",
                        "areas",
                        "crosshair",
                        // "line",
                        "slices",
                        "points",
                        "mesh",
                        "legends",
                        DashedSolidLine // add the custome layer here
                    ]}
                />
            )
        }
        return <NoResult />
    }

    return (
        <ChartBlock>
            {
                title && <ChartTitle>{title}</ChartTitle>
            }
            <ChartWrapper xs={(size && size === 'xs') ? true : false}>
                {chartContent()}
            </ChartWrapper>
            {
                footer && <ChartFooter>{footer}</ChartFooter>
            }
        </ChartBlock>
    )
}