import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import "../styles/market.css";
import { Context } from "../pages/LoginMarket";
import { Card, CardBody, CardHeader, Label } from "reactstrap";
import { MyChart } from "./MyChart";
import { DataWithColorSeriesTypes, DataPointTypes } from "../types";
import { errornotify } from "./Message";
import moment from "moment";
import { Utility } from "../App";
import { useSelector } from "react-redux";
import {
  fetchUSNMarketAvgPrice,
  fetchUSNMarketLowestPrice,
  fetchUSNStateProviders,
} from "../api/usnMarket";
import TduSelector from "./TduSelector";
import Datepicker from "./Datepicker";

interface searchOptionObj {
  value: any;
  label: any;
}

const today = moment().format("YYYY-MM-DD");

const calDate = (val: string) => {
  return moment(val, "YYYY-MM-DD").subtract(30, "days").format("YYYY-MM-DD");
};

const toChartsData = (inputData: any, outputData: any) => {
  if (inputData) {
    return inputData.map((item: any) => {
      if (item.date && +item.average_price !== 0) {
        return outputData.push({ x: item.date, y: +item.average_price });
      }
    });
  } else {
    return (outputData = []);
  }
};
const toChartsDataLowest = (inputData: any, outputData: any) => {
  if (inputData) {
    return inputData.map((item: any) => {
      if (item.date && +item.lowest_price !== 0) {
        return outputData.push({ x: item.date, y: +item.lowest_price });
      }
    });
  } else {
    return (outputData = []);
  }
};

const USN_RateTrendMarketView = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const { utilityList } = useContext(Utility);
  const { provider, allProvider } = useContext(Context);
  const [compareProvider, setCompareProvider] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    utility_id: "",
    date: `${calDate(today)}&${today}`,
  });
  const [data, setData] = useState<DataWithColorSeriesTypes[]>([]);
  const [lowestData, setLowestData] = useState<DataWithColorSeriesTypes[]>([]);
  const [providerList, setProviderList] = useState(["", provider.provider_id]);
  const [searchProviderList, setSearchProviderList] = useState<
    Array<searchOptionObj>
  >([]);
  const [selectedTDU, setselectedTDU] = useState<any>();

  const getAvgPrice = async () => {
    setIsLoading(true);

    const promise = providerList.map((item) => {
      return fetchUSNMarketAvgPrice(
        currentLocation?.state_id,
        filterValue.utility_id,
        filterValue.date,
        "1",
        +item || ""
      );
    });
    const res = await Promise.all(promise);
    const resData: any = res.map((item, index) => {
      if (item.status === 1) {
        return item.response.average_price;
      } else {
        errornotify(item.message || `Error trend[${index + 1}]`);
        return null;
      }
    });

    let avgData: DataPointTypes[] = [];
    let providerData: DataPointTypes[] = [];
    toChartsData(resData[0], avgData);
    toChartsData(resData[1], providerData);

    setData([
      {
        id: `${provider ? provider.provider_name : ""}`,
        data: providerData,
        color: "hsl(207,54.0%,46.9%)",
      },
      {
        id: "Market Price",
        data: avgData,
        color: "hsl(118,40.6%,48.8%)",
      },
    ]);
    setIsLoading(false);
  };

  const getLowestPrice = async () => {
    setIsLoading(true);

    const promise = providerList.map((item) => {
      return fetchUSNMarketLowestPrice(
        currentLocation?.state_id,
        filterValue.utility_id,
        filterValue.date,
        "1",
        +item || ""
      );
    });
    const res = await Promise.all(promise);
    const resData: any = res.map((item, index) => {
      if (item.status === 1) {
        return item.response.lowest_price;
      } else {
        errornotify(item.message || `Error trend[${index + 1}]`);
        return null;
      }
    });

    let avgData: any = [];
    let providerData: any = [];
    toChartsDataLowest(resData[0], avgData);
    toChartsDataLowest(resData[1], providerData);
    setLowestData([
      {
        id: `${provider ? provider.provider_name : ""}`,
        data: providerData,
        color: "hsl(207,54.0%,46.9%)",
      },
      {
        id: "Market Price",
        data: avgData,
        color: "hsl(118,40.6%,48.8%)",
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    setselectedTDU("All TDU");
    setFilterValue({
      ...filterValue,
      utility_id: "",
    });
  }, [currentLocation]);

  useEffect(() => {
    if (+provider.provider_id && allProvider.length) {
      getAvgPrice();
      getLowestPrice();
    } else {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, utilityList, providerList]);

  useEffect(() => {
    const getStateProviders = () => {
      fetchUSNStateProviders(currentLocation.state_id).then((res) => {
        const formattedList =
          res?.response?.map((item: any) => ({
            value: item.provider_id,
            label: item.provider_name,
          })) || [];
        setSearchProviderList(formattedList);
      });
    };

    getStateProviders();
  }, [currentLocation]);

  useEffect(() => {
    if (+provider.provider_id && allProvider.length) {
      setProviderList(["", provider.provider_id]);
      // setCompareName(allProvider[0]?.provider_name)
    } else {
      return;
    }
  }, [provider, allProvider]);

  useEffect(() => {
    if (compareProvider) {
      changeCompare(compareProvider);
    } else {
      setData((datas) => {
        let arr = datas.slice(0);
        arr.splice(0, 1);
        return arr;
      });
      setLowestData((datas) => {
        let arr = datas.slice(0);
        arr.splice(0, 1);
        return arr;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareProvider, filterValue]);

  const changeUtilityId = (val: any) => {
    if (!val) {
      setselectedTDU("All TDU");
      setFilterValue({
        ...filterValue,
        utility_id: "",
      });
    } else {
      setselectedTDU(val.utility_name);
      setFilterValue({
        ...filterValue,
        utility_id: val.utility_id,
      });
    }
  };

  const changeDate = (val: any) => {
    setFilterValue({
      ...filterValue,
      date: `${calDate(moment(val).format("YYYY-MM-DD"))}&${moment(val).format(
        "YYYY-MM-DD"
      )}`,
    });
  };

  const compareFun = (datas: any, val: any, id: any) => {
    let arr = datas.slice(0);
    if (arr.length === 2) {
      arr.unshift({
        id: `${id.label ? id.label : ""}`,
        data: val,
        color: "hsl(359,79.5%,49.8%)",
      });
    } else {
      arr = datas.map((item: any, index: any) => {
        if (index === 0) {
          return {
            id: `${id.label ? id.label : ""}`,
            data: val,
            color: "hsl(359,79.5%,49.8%)",
          };
        } else {
          return item;
        }
      });
    }
    return arr;
  };

  const changeCompare = async (id: any) => {
    setIsLoading(true);

    const avg_res = await fetchUSNMarketAvgPrice(
      currentLocation?.state_id,
      filterValue.utility_id,
      filterValue.date,
      "1",
      id.value
    );
    const low_res = await fetchUSNMarketLowestPrice(
      currentLocation?.state_id,
      filterValue.utility_id,
      filterValue.date,
      "1",
      id.value
    );
    if (avg_res.status === 1) {
      let avgCompare: any = [];
      toChartsData(avg_res.response.average_price, avgCompare);
      setData((datas) => compareFun(datas, avgCompare, id));
    } else {
      errornotify(avg_res.message || `Error trend[average]`);
    }
    if (low_res.status === 1) {
      let lowCompare: any = [];
      toChartsDataLowest(low_res.response.lowest_price, lowCompare);
      setLowestData((datas) => compareFun(datas, lowCompare, id));
    } else {
      errornotify(low_res.message || `Error trend[lowest]`);
    }
    setIsLoading(false);
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Market View – Rate Trending
          </h4>
        </CardHeader>
        <CardBody>
          <React.Fragment>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap gap-2">
                <TduSelector
                  value={selectedTDU}
                  handleChange={(val: string) => changeUtilityId(val)}
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center pc-compareSelect gap-2">
                  <Label
                    htmlFor="aria-example-input"
                    className="form-label text-muted mb-0"
                    style={{ fontWeight: "400" }}
                  >
                    Compare with
                  </Label>
                  <Select
                    defaultValue={searchProviderList[0]}
                    value={compareProvider}
                    isClearable
                    onChange={(val) => setCompareProvider(val)}
                    options={searchProviderList}
                  />
                </div>
                <Datepicker handleChangeDate={(val: any) => changeDate(val)} />
              </div>
            </div>
            <MyChart
              type="price"
              title="Average Rate"
              data={data}
              loading={isLoading}
            />
            <MyChart
              type="price"
              title="Lowest Rate"
              data={lowestData}
              loading={isLoading}
            />
          </React.Fragment>
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_RateTrendMarketView;
