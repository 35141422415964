import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Auth } from 'aws-amplify';
import { changeREPDisplay, successSnackbar } from "../store/actions";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProviderList, updateProvider } from '../api/market';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useHistory } from 'react-router-dom';


export const ChangeRep = () => {
    const dispatch = useDispatch()
    const [providerId, setProviderId] = useState<any>();
    const [repList, setRepList] = useState([]);
    const history = useHistory();
    const { openChangeREP } = useSelector((state: any) => ({
        openChangeREP: state.Layout.openChangeREP
    }))

    const logout = async () => {
        try {
            await Auth.signOut();
            history.push('/login');
            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getProviderList();
    }, [])
    const handleClose = () => {
        setProviderId(null);
        dispatch(changeREPDisplay(false))
    }

    const getProviderList = () => {
        fetchAllProviderList().then((res) => {
            if (res.status === 1) {
                setRepList(res.response);
            } else {
                setRepList([]);
            }
        })
    }

    const onSubmit = () => {
        updateProvider(providerId).then((res)=>{
            if (res.status === 1) {
                handleClose();
                logout();
                dispatch(successSnackbar(true));
            }
        })
    }


    const handleSelect = (e: any) => {
        if (e.target.value) {
            setProviderId(e.target.value)
        }
    }
    return (
        <Modal isOpen={openChangeREP} toggle={handleClose} centered >
            <ModalHeader toggle={handleClose}>Change REP</ModalHeader>
            <form>
                <ModalBody>
                    <p>Select provider</p>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=" "
                            onChange={(e)=>handleSelect(e)}
                            
                        >
                            {repList && repList.map((item: any) => {
                                return <MenuItem value={item.provider_id} key={item.provider_id} >{item.provider_alias || '-'}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => onSubmit()} disabled={providerId? false :true}>Submit</Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}