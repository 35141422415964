import React, { useEffect, useState } from 'react'
import Loader from './Common/Loader'
import '../styles/market.css'
import { fetchAvgPrice, fetchLowestPrice, fetchTerms } from '../api/market'
import { Button, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { MyChart } from './MyChart'
import { DataSeriesTypes, UtilityDataTypes } from '../types'
import { errornotify } from './Message'
import moment from 'moment';
import TduSelector from './TduSelector'

const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');


const MarketTrend = () => {
    const [utilityId, setUtilityId] = useState('1')
    const [price, setPrice] = useState('500')
    const [term, setTerm] = useState('');
    const [selectedTDU, setselectedTDU] = useState<any>();
    const [termList, setTermList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [filterValue, setFilterValue] = useState({
        utility_id: '',
        price: 500,
        date: `${startDate}&${endDate}`,
        term: '',
    })
    const [data, setData] = useState<DataSeriesTypes[]>([])
    const [openTerms, setOpenTerms] = useState(false)


    const getAvgPrice = async () => {
        setIsLoading(true)

        const res = await fetchAvgPrice({
            ...filterValue
        })

        const low_res = await fetchLowestPrice({
            ...filterValue
        })
        let resData = []
        if (res.status === 1) {
            resData = res.response.average_price
        } else {
            setIsLoading(false)
            return errornotify(res.message)
        }

        const avgData = resData.map((item: any) => {
            if (item.average_price !== 0) {
                return { x: item.date, y: +item.average_price }
            } else {
                return { x: item.date, y: null }
            }
        })

        let low_resData: any[] = []
        if (low_res.status === 1) {
            low_resData = low_res.response.lowest_price
        } else {
            setIsLoading(false)
            return errornotify(low_res.message)
        }

        let lowData: any = []
        if (low_resData.length !== 0) low_resData.map((item: any) => {
            if (item.lowest_price !== 0) {
                return lowData.push({ x: item.date, y: +item.lowest_price })
            } else {
                return lowData.push({ x: item.date, y: null })
            }
        })

        setData([
            {
                id: 'Lowest Price',
                data: lowData.filter((i: any) => i.y),
            },
            {
                id: 'Average Price',
                data: avgData.filter((i: any) => i.y),
            },
        ])
        setIsLoading(false)
    }

    useEffect(() => {
        getAvgPrice()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue])

    useEffect(() => {
        const getTerms = async () => {
            const res = await fetchTerms(+utilityId)
            if (res.status !== 1) {
                return
            }
            setTermList(res.response)
        }
        getTerms()
    }, [utilityId])

    const changeUtilityId = (val: any) => {
        if (val) {
            setselectedTDU(val.utility_name)
            setUtilityId(val.utility_id)
            setFilterValue({
                ...filterValue,
                utility_id: val.utility_id,
            })
        } else {
            setselectedTDU('All TDU')
            setUtilityId('')
            setFilterValue({
                ...filterValue,
                utility_id: '',
            })
        }
    }
    const changeUsage = (val: string) => {
        setPrice(val)
        setFilterValue({
            ...filterValue,
            price: +val,
        })
    }

    const changeTerm = (val: any) => {
        setTerm(val)
        setFilterValue({
            ...filterValue,
            term: val,
        })
    }

    return (
        <div className="layout-width">
            <Card style={{ minHeight: '600px' }}>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Rate Trends by TDU/Usage/Term</h4>
                </CardHeader>
                <CardBody>
                    <div className="d-flex flex-wrap gap-2">
                        <TduSelector
                            value={selectedTDU}
                            handleChange={(val: string) => changeUtilityId(val)}
                        />
                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3 mb-3">
                        {
                            ['500', '1000', '2000'].map((val: string, idx: number) => (
                                <Button
                                    onClick={() => changeUsage(val)}
                                    color={price === val ? 'secondary' : 'light'}
                                    key={idx}
                                >
                                    {val} kWh
                                </Button>
                            ))
                        }
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <Dropdown isOpen={openTerms} toggle={() => setOpenTerms(!openTerms)}>
                            <DropdownToggle tag="button" className="btn btn-light">
                                    {term ? `${term} Month` : 'All Term'} <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu style={{ overflow: 'scroll', height: '20rem' }}>
                                    <DropdownItem key={'allterm'} onClick={() => changeTerm('')}>
                                        All Term
                                    </DropdownItem>
                                    {termList.map((item: any, index: any) => (
                                        <DropdownItem key={index} onClick={() => changeTerm(item)}>
                                            {item} Month
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                            <MyChart
                                type='homeprice'
                                title='24-Month Rate Trends'
                                footer='Month/Year'
                                data={data[0]?.data.length ? data : []}
                            />
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        </div>
    )
}
export default MarketTrend

