import React, { useEffect, useState } from "react";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { MyChart } from "./MyChart";
import { DataWithColorSeriesTypes } from "../types";
import moment from "moment";
import { useSelector } from "react-redux";
import { fetchAvgLowestPTCRateUSN } from "../api/stateInsight";

const today = moment().format("YYYY-MM-DD");

const USN_AvgLowestPTCRate = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState("3");
  const [filterValue, setFilterValue] = useState({
    date: `${moment().subtract(range, "months").format("YYYY-MM-DD")}&${today}`,
  });
  const [data, setData] = useState<DataWithColorSeriesTypes[]>([]);

  const getAvgPrice = async () => {
    setIsLoading(true);

    const res = await fetchAvgLowestPTCRateUSN(
      currentLocation?.state_id,
      "1",
      filterValue.date
    );

    if (res.response) {
      const formattedData = [
        {
          id: "Lowest Rate",
          data: res.response.map((item: any) => ({
            x: moment(item.date, "YYYY-MM").format("MM/DD/YYYY"),
            y: item.lowest_ptc,
          })),
          color: "hsl(207,54.0%,46.9%)",
        },
        {
          id: "Average Rate",
          data: res.response.map((item: any) => ({
            x: moment(item.date, "YYYY-MM").format("MM/DD/YYYY"),
            y: item.average_ptc,
          })),
          color: "hsl(118,40.6%,48.8%)",
        },
      ];
      setData(formattedData);
    } else {
      setData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAvgPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, filterValue]);

  useEffect(() => {
    setData((datas) => {
      let arr = datas.slice(0);
      arr.splice(0, 1);
      return arr;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Average and Lowest PTC Rate in {currentLocation?.state_name}
          </h4>
        </CardHeader>
        <CardBody>
          <React.Fragment>
            <div className="d-flex flex-wrap justify-content-end gap-2">
              <div className="d-flex flex-wrap gap-2">
                {["3", "6", "12"].map((val: string, idx: number) => (
                  <Button
                    onClick={() => {
                      setRange(val);
                      setFilterValue({
                        ...filterValue,
                        date: `${moment()
                          .subtract(val, "months")
                          .format("YYYY-MM-DD")}&${today}`,
                      });
                    }}
                    color={range === val ? "secondary" : "light"}
                    key={idx}
                  >
                    {val} Months
                  </Button>
                ))}
                {/* <Datepicker handleChangeDate={(val: any) => changeDate(val)} /> */}
              </div>
            </div>
            <MyChart type="homeprice" data={data} loading={isLoading} />
          </React.Fragment>
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_AvgLowestPTCRate;
