import React, { createContext, useEffect, useState } from "react";
import LoginMarketSummary from "../Components/LoginMarketSummary";
import { fetchLoginProviderList, fetchProviderList } from "../api/market";
import { Helmet } from "react-helmet";
import LoginMarketTrend from "../Components/LoginMarketTrend";
import LoginTopPlan from "../Components/LoginTopPlan";
import { MySummary } from "../Components/MySummary";
import { errornotify } from "../Components/Message";
import MarketSummary from "../Components/MarketSummary";
import { useSelector } from "react-redux";
import { fetchUSNProviderList } from "../api/usnMarket";
import USN_AllPlansMarketView from "../Components/USN_AllPlansMarketView";
import USN_RateTrendMarketView from "../Components/USN_RateTrendMarketView";
import USN_AverageRateMarketView from "../Components/USN_AverageRateMarketView";
import USN_PTCRateMarketView from "../Components/USN_PTCRateMarketView";

export const Context = createContext<any>({});

export const LoginMarket = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";

  const [provider, setProvider] = useState({
    provider_id: "",
    provider_name: "",
    time: "",
  });
  const [allProvider, setAllProvider] = useState([]);

  const getProvider = async () => {
    const res = isTX
      ? await fetchProviderList()
      : await fetchUSNProviderList(currentLocation?.state_id);
    if (res.status !== 1) {
      return errornotify(res.message || "Error");
    }

    setProvider({
      provider_id: res.response.provider_id,
      provider_name: res.response.provider_name,
      time: res.response.time,
    });
  };

  const getAllProvider = async () => {
    const res = await fetchLoginProviderList();
    if (res.status !== 1) {
      return errornotify(res.message || "Error");
    }
    setAllProvider(res.response);
  };

  useEffect(() => {
    getAllProvider();
    getProvider();
  }, [currentLocation]);

  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <Context.Provider
        value={{ provider, setProvider, allProvider, setAllProvider }}
      >
        <MySummary />
        {isTX ? <TxComps /> : <UsnComps />}
      </Context.Provider>
    </div>
  );
};

const TxComps = () => {
  return (
    <>
      <LoginMarketTrend />
      <LoginMarketSummary />
      <LoginTopPlan />
      <MarketSummary />
    </>
  );
};

const UsnComps = () => {
  return (
    <>
      <USN_RateTrendMarketView />
      <USN_AverageRateMarketView />
      <USN_PTCRateMarketView />
      <USN_AllPlansMarketView />
    </>
  );
};
